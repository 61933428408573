/*
 * Copyright (C) 2023 - Potentially Ltd
 *
 * Please see distribution for license.
 */
@import 'rem';
@import 'variables';
@import 'styleguide';
@import 'colors';

body {
  &[dir='rtl'],
  [dir='rtl'] {
    .chips-list {
      .chip-item {
        @include ptl-direction(margin, left, rem(4), true);

        .chip-item-icon {
          @include ptl-direction(margin, right, rem(8), true);
        }
      }
    }
  }

  &[dir='ltr'],
  [dir='ltr'] {
    .chips-list {
      .chip-item {
        @include ptl-direction(margin, right, rem(4), true);

        .chip-item-icon {
          @include ptl-direction(margin, left, rem(8), true);
        }
      }
    }
  }

  .chips-list {
    .chip-item {
      @extend .small;
      display: inline-flex;
      vertical-align: top;
      align-items: center;
      padding: rem(3) rem(14);
      border-radius: rem(24);
      margin-bottom: rem(8);
      transition: background-color 0.3s ease;
      border: 0;
      outline: 0;
      max-width: 100%;
      box-sizing: border-box;

      .chip-text {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 100%;
        box-sizing: border-box;
      }

      &.has-action-btn {
        @include ptl-direction(padding, right, rem(8), true);
      }

      &.chip-primary {
        background-color: $g-color11;
        color: $g-color10 !important;
        fill: $g-color10 !important;
      }

      &.chip-interactive {
        background-color: $g-color14;
        color: $g-color13 !important;
        fill: $g-color13 !important;
      }

      &.chip-warning {
        background-color: $g-color21;
        color: $g-color20 !important;
        fill: $g-color20 !important;

        .svg {
          fill: $g-color20;
        }
      }

      &.chip-positive {
        background-color: $g-color24;
        color: $g-color10 !important;
        fill: $g-color10 !important;

        .svg {
          fill: $g-color10;
        }
      }

      &.chip-small {
        @extend .extra-small;
      }

      &.pointer {
        cursor: pointer;

        &:hover {
          background-color: $color--lavender-blue;

          &.chip-primary {
            color: $g-color11;
            background-color: $g-color12;
          }
        }
      }

      &.is_active {
        background-color: $color--pale-blue;
      }

      .chip-item-icon {
        cursor: pointer;
        width: rem(20);
        height: rem(20);
        line-height: rem(20);
      }
    }
  }

  .chip-big-warning {
    max-width: fit-content;
    display: flex;
    align-items: center;
    column-gap: rem(5);
    border-radius: rem(10);
    padding: rem(2) rem(7);
    background-color: $g-color21;
    fill: $g-color20;
    text-transform: capitalize;
    color: $g-color20;

    svg {
      min-width: rem(20);
    }

    .title {
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 100%;
    }
  }
}

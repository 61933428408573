/*
 * Copyright (C) 2024 - Potentially Ltd
 *
 * Please see distribution for license.
 */
@import 'rem';
@import 'variables';
@import 'styleguide';
@import 'colors';
@import 'mixins';

body {
  &[dir='rtl'],
  [dir='rtl'] {
    .group-chips-list {
      .group-chip-type {
        @include ptl-direction(margin, right, rem(-10), true);
      }
    }
  }

  &[dir='ltr'],
  [dir='ltr'] {
    .group-chips-list {
      .group-chip-type {
        @include ptl-direction(margin, left, rem(-10), true);
      }
    }
  }

  .group-chips-list {
    .group-chip-item {
      @extend .small;
      display: inline-flex;
      vertical-align: top;
      align-items: center;
      padding: rem(0) rem(10);
      border-radius: rem(24);
      margin-bottom: rem(8);
      transition: background-color 0.3s ease;
      outline: 0;
      max-width: 100%;
      box-sizing: border-box;
      border: rem(1) solid $g-color6;
      gap: rem(10);

      .group-chip-type {
        padding: 0 rem(12);
        border-radius: rem(27);
        background-color: $g-color11;
        color: $color--white;
        text-transform: lowercase;

        &:first-letter {
          text-transform: uppercase;
        }
      }

      .group-chip-text {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 100%;
        box-sizing: border-box;
      }
    }
  }
}

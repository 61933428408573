/*
 * Copyright (C) 2023 - Potentially Ltd
 *
 * Please see distribution for license.
 */
@import 'colors';
@import 'rem';

body {
  &[dir='rtl'],
  [dir='rtl'] {
    .mat-button,
    .mat-raised-button,
    .mat-stroked-button,
    .mat-flat-button {
      &.btn-with-loader {
        .is-right {
          @include ptl-direction(margin, right, rem(10), true);
        }

        .is-left {
          @include ptl-direction(margin, left, rem(10), true);
        }
      }

      mat-icon,
      i,
      .svg-icon {
        &.icon-right {
          @include ptl-direction(margin, right, rem(5), true);
          @include ptl-direction(margin, left, rem(-6), true);
        }

        &.icon-left {
          @include ptl-direction(margin, left, rem(5), true);
          @include ptl-direction(margin, right, rem(-6), true);
        }
      }
    }

    .mat-fab {
      &.mat-button-base {
        mat-icon,
        i,
        .svg-icon {
          &.icon-right {
            @include ptl-direction(margin, right, rem(5), true);
            @include ptl-direction(margin, left, rem(-6), true);
          }

          &.icon-left {
            @include ptl-direction(margin, left, rem(5), true);
            @include ptl-direction(margin, right, rem(-6), true);
          }
        }
      }
    }
  }

  &[dir='ltr'],
  [dir='ltr'] {
    .mat-button,
    .mat-raised-button,
    .mat-stroked-button,
    .mat-flat-button {
      &.btn-with-loader {
        .is-right {
          @include ptl-direction(margin, left, rem(10), true);
        }

        .is-left {
          @include ptl-direction(margin, right, rem(10), true);
        }
      }

      mat-icon,
      i,
      .svg-icon {
        &.icon-right {
          @include ptl-direction(margin, left, rem(5), true);
          @include ptl-direction(margin, right, rem(-6), true);
        }

        &.icon-left {
          @include ptl-direction(margin, right, rem(5), true);
          @include ptl-direction(margin, left, rem(-6), true);
        }
      }
    }

    .mat-fab {
      &.mat-button-base {
        mat-icon,
        i,
        .svg-icon {
          &.icon-right {
            @include ptl-direction(margin, left, rem(5), true);
            @include ptl-direction(margin, right, rem(-6), true);
          }

          &.icon-left {
            @include ptl-direction(margin, right, rem(5), true);
            @include ptl-direction(margin, left, rem(-6), true);
          }
        }
      }
    }
  }

  .mat-button,
  .mat-raised-button,
  .mat-stroked-button,
  .mat-flat-button {
    &.mat-button-base {
      vertical-align: top;
      min-width: rem(68);
      height: rem(36);
      padding: rem(8) rem(18);
      box-sizing: border-box;
      border-radius: rem(4);
      font-weight: 700;
      font-size: rem(13);
      line-height: rem(20);
      cursor: pointer;
      letter-spacing: 0;
      font-family: 'DM Sans', sans-serif;
    }

    &.btn-with-loader {
      .mat-button-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .mat-progress-spinner,
      .mat-spinner {
        circle {
          stroke: currentColor;
        }
      }
    }

    mat-icon,
    i,
    .svg-icon {
      width: rem(20);
      height: rem(20);
      display: inline-block;
      vertical-align: middle;
      font-size: rem(21);
      line-height: rem(20);
      text-align: center;
    }

    .svg-icon {
      fill: currentColor;
    }
  }

  .mat-icon-button {
    &.mat-button-base {
      width: rem(36);
      height: rem(36);
      line-height: rem(36);
      padding: 0;
      display: inline-flex;
      vertical-align: middle;
      align-items: center;
      justify-content: center;

      &:hover {
        opacity: 0.7;
      }

      &.mat-potentially {
        color: var(--primary-button-hover-color);

        &:hover {
          background-color: var(--primary-button-hover-bg);
        }
      }

      &.mat-potentially-secondary {
        color: var(--potentially-button-bg);

        &:hover {
          background-color: var(--primary-button-hover-bg);
        }
      }

      &.mat-primary {
        color: var(--primary-button-hover-color);

        &:hover {
          background-color: var(--primary-button-hover-bg);
        }
      }

      &.mat-warn {
        color: var(--warning-button-hover-color);

        &:hover {
          background-color: var(--warning-button-hover-bg);
        }
      }

      &.mat-orange {
        color: var(--orange-button-hover-color);

        &:hover {
          background-color: var(--orange-button-hover-bg);
        }
      }

      &.mat-accent {
        color: var(--secondary-button-color);

        &:hover {
          background-color: var(--secondary-button-hover-bg);
        }
      }

      &.mat-tertiary {
        color: var(--tertiary-button-color);

        &:hover {
          background-color: var(--tertiary-button-hover-bg);
        }
      }

      mat-icon,
      i {
        font-size: rem(21);
      }
    }
  }

  .mat-fab {
    &.mat-button-base {
      width: rem(36);
      height: rem(36);
      line-height: rem(36);
      border-radius: 50%;
      box-shadow: none;

      .mat-button-wrapper {
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &.mat-potentially {
        background-color: var(--potentially-button-bg);
        color: var(--primary-button-color);

        &:hover {
          background-color: var(--primary-button-hover-bg);
          color: var(--primary-button-hover-color);
        }
      }

      &.mat-potentially-secondary {
        background-color: var(--primary-button-color);
        color: var(--potentially-button-bg);

        &:hover {
          background-color: var(--primary-button-hover-bg);
          color: var(--primary-button-hover-color);
        }
      }

      &.mat-primary {
        background-color: var(--primary-button-bg);
        color: var(--primary-button-color);

        &:hover {
          background-color: var(--primary-button-hover-bg);
          color: var(--primary-button-hover-color);
        }
      }

      &.mat-warn {
        color: var(--warning-button-color);
        background-color: var(--warning-button-bg);

        &:hover {
          color: var(--warning-button-hover-color);
          background-color: var(--warning-button-hover-bg);
        }
      }

      &.mat-orange {
        color: var(--orange-button-color);
        background-color: var(--orange-button-bg);

        &:hover {
          color: var(--orange-button-hover-color);
          background-color: var(--orange-button-hover-bg);
        }
      }

      &.mat-accent {
        background-color: var(--secondary-button-bg);
        color: var(--secondary-button-color);
        border: rem(1) solid var(--secondary-button-hover-bg);

        &:hover {
          background-color: var(--secondary-button-hover-bg);
          color: var(--secondary-button-hover-color);
        }
      }

      &.mat-tertiary {
        background-color: var(--tertiary-button-bg);
        color: var(--tertiary-button-color);

        &:hover {
          background-color: var(--tertiary-button-hover-bg);
          color: var(--tertiary-button-hover-color);
        }
      }

      mat-icon,
      i,
      .svg-icon {
        width: rem(20);
        height: rem(20);
        font-size: rem(21);
        line-height: rem(20);
        text-align: center;
      }

      .svg-icon {
        fill: currentColor;
      }
    }
  }

  .mat-button {
    &:not(.mat-button-disabled) {
      &.mat-potentially {
        color: var(--primary-button-hover-color);

        .mat-button-focus-overlay {
          background-color: var(--potentially-button-bg);
        }

        &:hover {
          &:not(.mat-button-disabled) {
            .mat-button-focus-overlay {
              opacity: 0.07;
            }
          }
        }
      }

      &.mat-potentially-secondary {
        color: var(--potentially-button-bg);

        .mat-button-focus-overlay {
          background-color: var(--potentially-button-bg);
        }

        &:hover {
          &:not(.mat-button-disabled) {
            .mat-button-focus-overlay {
              opacity: 0.07;
            }
          }
        }
      }

      &.mat-primary {
        color: var(--primary-button-hover-color);

        .mat-button-focus-overlay {
          background-color: var(--primary-button-bg);
        }

        &:hover {
          &:not(.mat-button-disabled) {
            .mat-button-focus-overlay {
              opacity: 0.07;
            }
          }
        }
      }

      &.mat-accent {
        color: var(--secondary-button-hover-color);

        .mat-button-focus-overlay {
          background-color: var(--secondary-button-color);
        }

        &:hover {
          &:not(.mat-button-disabled) {
            .mat-button-focus-overlay {
              opacity: 0.07;
            }
          }
        }
      }

      &.mat-warn {
        color: var(--warning-button-hover-color);

        .mat-button-focus-overlay {
          background-color: var(--warning-button-bg);
        }

        &:hover {
          &:not(.mat-button-disabled) {
            .mat-button-focus-overlay {
              opacity: 0.07;
            }
          }
        }
      }

      &.mat-orange {
        color: var(--orange-button-hover-color);

        .mat-button-focus-overlay {
          background-color: var(--orange-button-bg);
        }

        &:hover {
          &:not(.mat-button-disabled) {
            .mat-button-focus-overlay {
              opacity: 0.07;
            }
          }
        }
      }

      &.mat-tertiary {
        color: var(--tertiary-button-hover-color);

        .mat-button-focus-overlay {
          background-color: var(--tertiary-button-color);
        }

        &:hover {
          &:not(.mat-button-disabled) {
            .mat-button-focus-overlay {
              opacity: 0.07;
            }
          }
        }
      }
    }
  }

  .mat-raised-button {
    transition:
      background 400ms cubic-bezier(0.25, 0.8, 0.25, 1),
      color 400ms cubic-bezier(0.25, 0.8, 0.25, 1);

    &:hover {
      transition:
        background 400ms cubic-bezier(0.25, 0.8, 0.25, 1),
        color 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
    }

    &:not(.mat-button-disabled) {
      &.mat-button-base {
        box-shadow: none;
      }

      &.mat-potentially {
        color: var(--primary-button-hover-color);
        background-color: var(--primary-button-hover-bg);

        &:hover {
          color: var(--primary-button-color);
          background-color: var(--potentially-button-bg);
        }
      }

      &.mat-potentially-secondary {
        color: var(--potentially-button-bg);
        background-color: var(--primary-button-color);

        &:hover {
          color: var(--primary-button-color);
          background-color: var(--potentially-button-bg);
        }
      }

      &.mat-primary {
        color: var(--primary-button-hover-color);
        background-color: var(--primary-button-hover-bg);

        &:hover {
          color: var(--primary-button-color);
          background-color: var(--primary-button-bg);
        }
      }

      &.mat-warn {
        color: var(--warning-button-hover-color);
        background-color: var(--warning-button-hover-bg);

        &:hover {
          color: var(--warning-button-color);
          background-color: var(--warning-button-bg);
        }
      }

      &.mat-orange {
        color: var(--orange-button-hover-color);
        background-color: var(--orange-button-hover-bg);

        &:hover {
          color: var(--orange-button-color);
          background-color: var(--orange-button-bg);
        }
      }

      &.mat-accent {
        color: var(--secondary-button-hover-color);
        background-color: var(--secondary-button-hover-bg);
        border: rem(1) solid var(--secondary-button-hover-bg);

        &:hover {
          color: var(--secondary-button-color);
          background-color: var(--secondary-button-bg);
        }
      }

      &.mat-tertiary {
        color: var(--tertiary-button-hover-color);
        background-color: var(--tertiary-button-hover-bg);

        &:hover {
          color: var(--tertiary-button-color);
          background-color: var(--tertiary-button-bg);
        }
      }
    }
  }

  .mat-stroked-button {
    transition:
      background 400ms cubic-bezier(0.25, 0.8, 0.25, 1),
      color 400ms cubic-bezier(0.25, 0.8, 0.25, 1);

    &:hover {
      transition:
        background 400ms cubic-bezier(0.25, 0.8, 0.25, 1),
        color 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
    }

    &:not(.mat-button-disabled) {
      &.mat-potentially {
        color: var(--primary-button-hover-color);
        border-color: var(--potentially-button-bg);

        &:hover {
          color: var(--primary-button-color);
          background-color: var(--potentially-button-bg);
        }
      }

      &.mat-potentially-secondary {
        color: var(--potentially-button-bg);
        border-color: var(--secondary-button-hover-bg);

        &:hover {
          color: var(--primary-button-color);
          border-color: var(--potentially-button-bg);
          background-color: var(--potentially-button-bg);
        }
      }

      &.mat-primary {
        color: var(--primary-button-hover-color);
        border-color: var(--primary-button-bg);

        &:hover {
          color: var(--primary-button-color);
          background-color: var(--primary-button-bg);
        }
      }

      &.mat-warn {
        color: var(--warning-button-hover-color);
        border-color: var(--warning-button-bg);

        &:hover {
          color: var(--warning-button-color);
          background-color: var(--warning-button-bg);
        }
      }

      &.mat-orange {
        color: var(--orange-button-hover-color);
        border-color: var(--orange-button-bg);

        &:hover {
          color: var(--orange-button-color);
          background-color: var(--orange-button-bg);
        }
      }

      &.mat-accent {
        color: var(--secondary-button-hover-color);
        background-color: var(--secondary-button-bg);
        border-color: var(--secondary-button-hover-bg);

        .mat-button-focus-overlay {
          background-color: transparent;
        }

        &:hover {
          color: var(--secondary-button-color);
          background-color: var(--secondary-button-hover-bg);
        }
      }

      &.mat-tertiary {
        color: var(--tertiary-button-hover-color);
        background-color: var(--tertiary-button-bg);

        .mat-button-focus-overlay {
          background-color: transparent;
        }

        &:hover {
          color: var(--tertiary-button-color);
          background-color: var(--tertiary-button-hover-bg);
        }
      }
    }
  }

  .mat-flat-button {
    transition:
      background 400ms cubic-bezier(0.25, 0.8, 0.25, 1),
      color 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
    border: none;

    &:hover {
      transition:
        background 400ms cubic-bezier(0.25, 0.8, 0.25, 1),
        color 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
    }

    &:not(.mat-button-disabled) {
      &.mat-potentially {
        color: var(--primary-button-color);
        border: rem(1) solid var(--potentially-button-bg);
        background-color: var(--potentially-button-bg);

        &:hover {
          color: var(--primary-button-hover-color);
          border: rem(1) solid var(--primary-button-hover-color);
          background-color: var(--primary-button-hover-bg);
        }
      }

      &.mat-potentially-secondary {
        color: var(--primary-button-color);
        border: rem(1) solid var(--potentially-button-bg);
        background-color: var(--potentially-button-bg);

        &:hover {
          color: var(--primary-button-hover-color);
          border: rem(1) solid var(--primary-button-hover-color);
          background-color: var(--primary-button-hover-bg);
        }
      }

      &.mat-primary {
        color: var(--primary-button-color);
        border: rem(1) solid var(--primary-button-bg);
        background-color: var(--primary-button-bg);

        &:hover {
          color: var(--primary-button-hover-color);
          border: rem(1) solid var(--primary-button-hover-color);
          background-color: var(--primary-button-hover-bg);
        }
      }

      &.mat-warn {
        color: var(--warning-button-color);
        border: rem(1) solid var(--warning-button-bg);
        background-color: var(--warning-button-bg);

        &:hover {
          color: var(--warning-button-hover-color);
          border: rem(1) solid var(--warning-button-hover-color);
          background-color: var(--warning-button-hover-bg);
        }
      }

      &.mat-orange {
        color: var(--orange-button-color);
        border: rem(1) solid var(--orange-button-bg);
        background-color: var(--orange-button-bg);

        &:hover {
          color: var(--orange-button-hover-color);
          border: rem(1) solid var(--orange-button-hover-bg);
          background-color: var(--orange-button-hover-bg);
        }
      }

      &.mat-accent {
        color: var(--secondary-button-color);
        border: rem(1) solid var(--secondary-button-hover-bg);
        background-color: var(--secondary-button-bg);

        &:hover {
          color: var(--secondary-button-hover-color);
          border: rem(1) solid var(--secondary-button-hover-color);
          background-color: var(--secondary-button-hover-bg);
        }
      }

      &.mat-tertiary {
        color: var(--tertiary-button-color);
        border: rem(1) solid var(--tertiary-button-bg);
        background-color: var(--tertiary-button-bg);

        &:hover {
          color: var(--tertiary-button-hover-color);
          border: rem(1) solid var(--tertiary-button-hover-color);
          background-color: var(--tertiary-button-hover-bg);
        }
      }
    }
  }

  .mat-button.rounded-button,
  .mat-raised-button.rounded-button,
  .mat-stroked-button.rounded-button,
  .mat-flat-button.rounded-button {
    border-radius: rem(12);
    padding: rem(8) rem(28);
  }

  .custom-image-button {
    &.mat-button-base {
      border: rem(1) solid $g-color1;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      padding: rem(8) rem(12);
      height: rem(38);

      &[disabled] {
        opacity: 0.4;
      }

      &:hover {
        border-color: $g-color23;
      }

      .button-content {
        display: flex;
        gap: rem(8);
      }
    }
  }
}

@font-face {
  font-family: 'icomoon';
  src: url('fonts/icomoon.eot?piqzkf');
  src:
    url('fonts/icomoon.eot?piqzkf#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?piqzkf') format('truetype'),
    url('fonts/icomoon.woff?piqzkf') format('woff'),
    url('fonts/icomoon.svg?piqzkf#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='ptl-icon-'],
[class*=' ptl-icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ptl-icon-icons8-add:before {
  content: '\eb77';
}
.ptl-icon-icons8-analyzing-skill:before {
  content: '\eb78';
}
.ptl-icon-icons8-audit:before {
  content: '\eb79';
}
.ptl-icon-icons8-comments:before {
  content: '\eb7a';
}
.ptl-icon-icons8-invite .path1:before {
  content: '\eb7b';
  color: rgb(0, 0, 0);
  opacity: 0.3;
}
.ptl-icon-icons8-invite .path2:before {
  content: '\eb7c';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.ptl-icon-icons8-invite .path3:before {
  content: '\eb7d';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.ptl-icon-icons8-invite .path4:before {
  content: '\eb7e';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.ptl-icon-icons8-invite .path5:before {
  content: '\eb7f';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.ptl-icon-icons8-schedule:before {
  content: '\eb80';
}
.ptl-icon-icons8-submit-for-approval:before {
  content: '\eb81';
}
.ptl-icon-icons8-task-completed:before {
  content: '\eb82';
}
.ptl-icon-icons8-trigger:before {
  content: '\eb83';
}
.ptl-icon-icons8-trophy:before {
  content: '\eb84';
}
.ptl-icon-icons8-warranty:before {
  content: '\eb85';
}
.ptl-icon-icons8-workflow:before {
  content: '\eb86';
}
.ptl-icon-building:before {
  content: '\ea12';
}
.ptl-icon-analysis:before {
  content: '\ea13';
}
.ptl-icon-add-user-group-man:before {
  content: '\ea14';
}
.ptl-icon-check-circle:before {
  content: '\ea6c';
}
.ptl-icon-charity:before {
  content: '\ea7c';
}
.ptl-icon-add-administrator:before {
  content: '\ea7d';
}
.ptl-icon-absorb:before {
  content: '\ea7e';
}
.ptl-icon-media:before {
  content: '\eb0b';
}
.ptl-icon-news-gradient:before {
  content: '\eb0c';
}
.ptl-icon-unfriend:before {
  content: '\eb0d';
}
.ptl-icon-tags:before {
  content: '\eb52';
}
.ptl-icon-south-dakota-state-bird:before {
  content: '\eb63';
}
.ptl-icon-socratic:before {
  content: '\eb64';
}
.ptl-icon-socrates:before {
  content: '\eb65';
}
.ptl-icon-remove-administrator:before {
  content: '\eb66';
}
.ptl-icon-new-message:before {
  content: '\eb67';
}
.ptl-icon-mail:before {
  content: '\eb68';
}
.ptl-icon-graph-report-script:before {
  content: '\eb69';
}
.ptl-icon-gifted:before {
  content: '\eb6a';
}
.ptl-icon-filter:before {
  content: '\eb6b';
}
.ptl-icon-emotion:before {
  content: '\eb6c';
}
.ptl-icon-edit-account:before {
  content: '\eb6d';
}
.ptl-icon-eccentric:before {
  content: '\eb6e';
}
.ptl-icon-distance:before {
  content: '\eb6f';
}
.ptl-icon-content:before {
  content: '\eb70';
}
.ptl-icon-collaboration:before {
  content: '\eb71';
}
.ptl-icon-class:before {
  content: '\eb72';
}
.ptl-icon-checklist:before {
  content: '\eb73';
}
.ptl-icon-behavior:before {
  content: '\eb74';
}
.ptl-icon-area-chart:before {
  content: '\eb75';
}
.ptl-icon-behavior2:before {
  content: '\eb76';
}
.ptl-icon-sort-right:before {
  content: '\eab5';
}
.ptl-icon-male-user .path1:before {
  content: '\eb4f';
  color: rgb(113, 194, 255);
}
.ptl-icon-male-user .path2:before {
  content: '\eb50';
  margin-left: -1em;
  color: rgb(68, 75, 84);
}
.ptl-icon-male-user .path3:before {
  content: '\eb51';
  margin-left: -1em;
  color: rgb(68, 75, 84);
}
.ptl-icon-up:before {
  content: '\ea49';
}
.ptl-icon-store:before {
  content: '\ea7a';
}
.ptl-icon-search1:before {
  content: '\eab6';
}
.ptl-icon-view:before {
  content: '\eab7';
}
.ptl-icon-right:before {
  content: '\eab8';
}
.ptl-icon-return1:before {
  content: '\eab9';
}
.ptl-icon-report-card:before {
  content: '\eae5';
}
.ptl-icon-plus1:before {
  content: '\eae6';
}
.ptl-icon-picture:before {
  content: '\eae7';
}
.ptl-icon-people1:before {
  content: '\eae8';
}
.ptl-icon-paint-brush:before {
  content: '\eae9';
}
.ptl-icon-page1:before {
  content: '\eb0f';
}
.ptl-icon-organization1:before {
  content: '\eb10';
}
.ptl-icon-note1:before {
  content: '\eb4c';
}
.ptl-icon-news1:before {
  content: '\eb4d';
}
.ptl-icon-new:before {
  content: '\eb4e';
}
.ptl-icon-library:before {
  content: '\eb53';
}
.ptl-icon-insert-clip:before {
  content: '\eb54';
}
.ptl-icon-insert:before {
  content: '\eb55';
}
.ptl-icon-header-1:before {
  content: '\eb56';
}
.ptl-icon-glasses:before {
  content: '\eb57';
}
.ptl-icon-fire:before {
  content: '\eb58';
}
.ptl-icon-ereader:before {
  content: '\eb59';
}
.ptl-icon-easy-to-find:before {
  content: '\eb5a';
}
.ptl-icon-collection:before {
  content: '\eb5b';
}
.ptl-icon-camera:before {
  content: '\eb5c';
}
.ptl-icon-calendar1:before {
  content: '\eb5d';
}
.ptl-icon-briefcase1:before {
  content: '\eb5e';
}
.ptl-icon-adjust:before {
  content: '\eb5f';
}
.ptl-icon-add-to-basket:before {
  content: '\eb60';
}
.ptl-icon-activity-feed:before {
  content: '\eb61';
}
.ptl-icon-abc:before {
  content: '\eb62';
}
.ptl-icon-hashtag:before {
  content: '\ea78';
}
.ptl-icon-paint-brush-1:before {
  content: '\ea79';
}
.ptl-icon-like-filled:before {
  content: '\ea77';
}
.ptl-icon-add .path1:before {
  content: '\e9fa';
  color: rgb(255, 255, 255);
}
.ptl-icon-add .path2:before {
  content: '\e9fb';
  margin-left: -1em;
  color: rgb(68, 75, 84);
}
.ptl-icon-add .path3:before {
  content: '\e9fc';
  margin-left: -1em;
  color: rgb(255, 105, 123);
}
.ptl-icon-add .path4:before {
  content: '\e9fd';
  margin-left: -1em;
  color: rgb(255, 105, 123);
}
.ptl-icon-agreement .path1:before {
  content: '\e9fe';
  color: rgb(255, 255, 255);
}
.ptl-icon-agreement .path2:before {
  content: '\e9ff';
  margin-left: -1em;
  color: rgb(113, 194, 255);
}
.ptl-icon-agreement .path3:before {
  content: '\ea00';
  margin-left: -1em;
  color: rgb(233, 238, 244);
}
.ptl-icon-agreement .path4:before {
  content: '\ea01';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.ptl-icon-agreement .path5:before {
  content: '\ea02';
  margin-left: -1em;
  color: rgb(68, 75, 84);
}
.ptl-icon-api:before {
  content: '\ea03';
}
.ptl-icon-arrow-up:before {
  content: '\ea04';
}
.ptl-icon-below .path1:before {
  content: '\ea05';
  color: rgb(255, 255, 255);
}
.ptl-icon-below .path2:before {
  content: '\ea06';
  margin-left: -1em;
  color: rgb(68, 75, 84);
}
.ptl-icon-below .path3:before {
  content: '\ea07';
  margin-left: -1em;
  color: rgb(113, 194, 255);
}
.ptl-icon-bookmark-1 .path1:before {
  content: '\ea08';
  color: rgb(255, 255, 255);
}
.ptl-icon-bookmark-1 .path2:before {
  content: '\ea09';
  margin-left: -1em;
  color: rgb(68, 75, 84);
}
.ptl-icon-bookmark-1 .path3:before {
  content: '\ea0a';
  margin-left: -1em;
  color: rgb(113, 194, 255);
}
.ptl-icon-box .path1:before {
  content: '\ea0b';
  color: rgb(255, 255, 255);
}
.ptl-icon-box .path2:before {
  content: '\ea0c';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.ptl-icon-box .path3:before {
  content: '\ea0d';
  margin-left: -1em;
  color: rgb(68, 75, 84);
}
.ptl-icon-box .path4:before {
  content: '\ea0e';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.ptl-icon-box .path5:before {
  content: '\ea0f';
  margin-left: -1em;
  color: rgb(68, 75, 84);
}
.ptl-icon-box-move-right:before {
  content: '\ea10';
}
.ptl-icon-briefcase:before {
  content: '\ea11';
}
.ptl-icon-calendar-1 .path1:before {
  content: '\ea15';
  color: rgb(255, 255, 255);
}
.ptl-icon-calendar-1 .path2:before {
  content: '\ea16';
  margin-left: -1em;
  color: rgb(68, 75, 84);
}
.ptl-icon-calendar-27 .path1:before {
  content: '\ea17';
  color: rgb(113, 194, 255);
}
.ptl-icon-calendar-27 .path2:before {
  content: '\ea18';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.ptl-icon-calendar-27 .path3:before {
  content: '\ea19';
  margin-left: -1em;
  color: rgb(68, 75, 84);
}
.ptl-icon-calendar-27 .path4:before {
  content: '\ea1a';
  margin-left: -1em;
  color: rgb(68, 75, 84);
}
.ptl-icon-calendar-27 .path5:before {
  content: '\ea1b';
  margin-left: -1em;
  color: rgb(68, 75, 84);
}
.ptl-icon-catalog .path1:before {
  content: '\ea1c';
  color: rgb(255, 255, 255);
}
.ptl-icon-catalog .path2:before {
  content: '\ea1d';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.ptl-icon-catalog .path3:before {
  content: '\ea1e';
  margin-left: -1em;
  color: rgb(113, 194, 255);
}
.ptl-icon-catalog .path4:before {
  content: '\ea1f';
  margin-left: -1em;
  color: rgb(113, 194, 255);
}
.ptl-icon-catalog .path5:before {
  content: '\ea20';
  margin-left: -1em;
  color: rgb(113, 194, 255);
}
.ptl-icon-catalog .path6:before {
  content: '\ea21';
  margin-left: -1em;
  color: rgb(113, 194, 255);
}
.ptl-icon-catalog .path7:before {
  content: '\ea22';
  margin-left: -1em;
  color: rgb(113, 194, 255);
}
.ptl-icon-catalog .path8:before {
  content: '\ea23';
  margin-left: -1em;
  color: rgb(113, 194, 255);
}
.ptl-icon-catalog .path9:before {
  content: '\ea24';
  margin-left: -1em;
  color: rgb(68, 75, 84);
}
.ptl-icon-catalog .path10:before {
  content: '\ea25';
  margin-left: -1em;
  color: rgb(113, 194, 255);
}
.ptl-icon-clipboard .path1:before {
  content: '\ea26';
  color: rgb(255, 255, 255);
}
.ptl-icon-clipboard .path2:before {
  content: '\ea27';
  margin-left: -1em;
  color: rgb(243, 230, 255);
}
.ptl-icon-clipboard .path3:before {
  content: '\ea28';
  margin-left: -1em;
  color: rgb(68, 75, 84);
}
.ptl-icon-clipboard .path4:before {
  content: '\ea29';
  margin-left: -1em;
  color: rgb(68, 75, 84);
}
.ptl-icon-clipboard .path5:before {
  content: '\ea2a';
  margin-left: -1em;
  color: rgb(68, 75, 84);
}
.ptl-icon-clipboard .path6:before {
  content: '\ea2b';
  margin-left: -1em;
  color: rgb(68, 75, 84);
}
.ptl-icon-closet .path1:before {
  content: '\ea2c';
  color: rgb(255, 255, 255);
}
.ptl-icon-closet .path2:before {
  content: '\ea2d';
  margin-left: -1em;
  color: rgb(68, 75, 84);
}
.ptl-icon-closet .path3:before {
  content: '\ea2e';
  margin-left: -1em;
  color: rgb(68, 75, 84);
}
.ptl-icon-coffee-to-go .path1:before {
  content: '\ea2f';
  color: rgb(255, 255, 255);
}
.ptl-icon-coffee-to-go .path2:before {
  content: '\ea30';
  margin-left: -1em;
  color: rgb(231, 231, 231);
}
.ptl-icon-coffee-to-go .path3:before {
  content: '\ea31';
  margin-left: -1em;
  color: rgb(168, 178, 198);
}
.ptl-icon-coffee-to-go .path4:before {
  content: '\ea32';
  margin-left: -1em;
  color: rgb(153, 162, 180);
}
.ptl-icon-coffee-to-go .path5:before {
  content: '\ea33';
  margin-left: -1em;
  color: rgb(168, 178, 198);
}
.ptl-icon-coffee-to-go .path6:before {
  content: '\ea34';
  margin-left: -1em;
  color: rgb(153, 162, 180);
}
.ptl-icon-coffee-to-go .path7:before {
  content: '\ea35';
  margin-left: -1em;
  color: rgb(243, 124, 126);
}
.ptl-icon-coffee-to-go .path8:before {
  content: '\ea36';
  margin-left: -1em;
  color: rgb(220, 113, 115);
}
.ptl-icon-coffee-to-go .path9:before {
  content: '\ea37';
  margin-left: -1em;
  color: rgb(244, 241, 231);
}
.ptl-icon-coffee-to-go .path10:before {
  content: '\ea38';
  margin-left: -1em;
  color: rgb(70, 76, 85);
}
.ptl-icon-coffee-to-go .path11:before {
  content: '\ea39';
  margin-left: -1em;
  color: rgb(70, 76, 85);
}
.ptl-icon-comments .path1:before {
  content: '\ea3a';
  color: rgb(255, 255, 255);
}
.ptl-icon-comments .path2:before {
  content: '\ea3b';
  margin-left: -1em;
  color: rgb(68, 75, 84);
}
.ptl-icon-comments .path3:before {
  content: '\ea3c';
  margin-left: -1em;
  color: rgb(113, 194, 255);
}
.ptl-icon-comments .path4:before {
  content: '\ea3d';
  margin-left: -1em;
  color: rgb(68, 75, 84);
}
.ptl-icon-company .path1:before {
  content: '\ea3e';
  color: rgb(255, 255, 255);
}
.ptl-icon-company .path2:before {
  content: '\ea3f';
  margin-left: -1em;
  color: rgb(68, 75, 84);
}
.ptl-icon-company .path3:before {
  content: '\ea40';
  margin-left: -1em;
  color: rgb(68, 75, 84);
}
.ptl-icon-company .path4:before {
  content: '\ea41';
  margin-left: -1em;
  color: rgb(113, 194, 255);
}
.ptl-icon-company .path5:before {
  content: '\ea42';
  margin-left: -1em;
  color: rgb(113, 194, 255);
}
.ptl-icon-company .path6:before {
  content: '\ea43';
  margin-left: -1em;
  color: rgb(113, 194, 255);
}
.ptl-icon-company .path7:before {
  content: '\ea44';
  margin-left: -1em;
  color: rgb(113, 194, 255);
}
.ptl-icon-company .path8:before {
  content: '\ea45';
  margin-left: -1em;
  color: rgb(113, 194, 255);
}
.ptl-icon-company .path9:before {
  content: '\ea46';
  margin-left: -1em;
  color: rgb(113, 194, 255);
}
.ptl-icon-company .path10:before {
  content: '\ea47';
  margin-left: -1em;
  color: rgb(113, 194, 255);
}
.ptl-icon-customer:before {
  content: '\ea48';
}
.ptl-icon-diploma-1 .path1:before {
  content: '\ea4a';
  color: rgb(255, 255, 255);
}
.ptl-icon-diploma-1 .path2:before {
  content: '\ea4b';
  margin-left: -1em;
  color: rgb(113, 194, 255);
}
.ptl-icon-diploma-1 .path3:before {
  content: '\ea4c';
  margin-left: -1em;
  color: rgb(68, 75, 84);
}
.ptl-icon-diploma-1 .path4:before {
  content: '\ea4d';
  margin-left: -1em;
  color: rgb(68, 75, 84);
}
.ptl-icon-diploma-1 .path5:before {
  content: '\ea4e';
  margin-left: -1em;
  color: rgb(68, 75, 84);
}
.ptl-icon-diploma-1 .path6:before {
  content: '\ea4f';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.ptl-icon-diploma-1 .path7:before {
  content: '\ea50';
  margin-left: -1em;
  color: rgb(68, 75, 84);
}
.ptl-icon-download-1:before {
  content: '\ea51';
  color: #444b54;
}
.ptl-icon-edit-1:before {
  content: '\ea52';
}
.ptl-icon-external-link .path1:before {
  content: '\ea53';
  color: rgb(113, 194, 255);
}
.ptl-icon-external-link .path2:before {
  content: '\ea54';
  margin-left: -1em;
  color: rgb(68, 75, 84);
}
.ptl-icon-facebook-like-1 .path1:before {
  content: '\ea55';
  color: rgb(255, 255, 255);
}
.ptl-icon-facebook-like-1 .path2:before {
  content: '\ea56';
  margin-left: -1em;
  color: rgb(68, 75, 84);
}
.ptl-icon-facebook-like-1 .path3:before {
  content: '\ea57';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.ptl-icon-facebook-like-1 .path4:before {
  content: '\ea58';
  margin-left: -1em;
  color: rgb(68, 75, 84);
}
.ptl-icon-favorite .path1:before {
  content: '\ea59';
  color: rgb(255, 255, 255);
}
.ptl-icon-favorite .path2:before {
  content: '\ea5a';
  margin-left: -1em;
  color: rgb(68, 75, 84);
}
.ptl-icon-favorite .path3:before {
  content: '\ea5b';
  margin-left: -1em;
  color: rgb(113, 194, 255);
}
.ptl-icon-filing-cabinet-1:before {
  content: '\ea5c';
}
.ptl-icon-fluorescent-bulb .path1:before {
  content: '\ea5d';
  color: rgb(255, 255, 255);
}
.ptl-icon-fluorescent-bulb .path2:before {
  content: '\ea5e';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.ptl-icon-fluorescent-bulb .path3:before {
  content: '\ea5f';
  margin-left: -1em;
  color: rgb(113, 194, 255);
}
.ptl-icon-fluorescent-bulb .path4:before {
  content: '\ea60';
  margin-left: -1em;
  color: rgb(113, 194, 255);
}
.ptl-icon-fluorescent-bulb .path5:before {
  content: '\ea61';
  margin-left: -1em;
  color: rgb(113, 194, 255);
}
.ptl-icon-fluorescent-bulb .path6:before {
  content: '\ea62';
  margin-left: -1em;
  color: rgb(113, 194, 255);
}
.ptl-icon-fluorescent-bulb .path7:before {
  content: '\ea63';
  margin-left: -1em;
  color: rgb(113, 194, 255);
}
.ptl-icon-fluorescent-bulb .path8:before {
  content: '\ea64';
  margin-left: -1em;
  color: rgb(113, 194, 255);
}
.ptl-icon-fluorescent-bulb .path9:before {
  content: '\ea65';
  margin-left: -1em;
  color: rgb(68, 75, 84);
}
.ptl-icon-fluorescent-bulb .path10:before {
  content: '\ea66';
  margin-left: -1em;
  color: rgb(68, 75, 84);
}
.ptl-icon-form:before {
  content: '\ea67';
}
.ptl-icon-gdpr-document .path1:before {
  content: '\ea68';
  color: rgb(68, 75, 84);
}
.ptl-icon-gdpr-document .path2:before {
  content: '\ea69';
  margin-left: -0.9423828125em;
  color: rgb(68, 75, 84);
}
.ptl-icon-gdpr-document .path3:before {
  content: '\ea6a';
  margin-left: -0.9423828125em;
  color: rgb(113, 194, 255);
}
.ptl-icon-gdpr-document .path4:before {
  content: '\ea6b';
  margin-left: -0.9423828125em;
  color: rgb(113, 194, 255);
}
.ptl-icon-gdpr-fingerprint:before {
  content: '\ea6d';
}
.ptl-icon-globe-1 .path1:before {
  content: '\ea6e';
  color: rgb(255, 255, 255);
}
.ptl-icon-globe-1 .path2:before {
  content: '\ea6f';
  margin-left: -1em;
  color: rgb(68, 75, 84);
}
.ptl-icon-globe-1 .path3:before {
  content: '\ea70';
  margin-left: -1em;
  color: rgb(113, 194, 255);
}
.ptl-icon-goal-1 .path1:before {
  content: '\ea71';
  color: rgb(140, 207, 185);
}
.ptl-icon-goal-1 .path2:before {
  content: '\ea72';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.ptl-icon-goal-1 .path3:before {
  content: '\ea73';
  margin-left: -1em;
  color: rgb(243, 124, 126);
}
.ptl-icon-goal-1 .path4:before {
  content: '\ea74';
  margin-left: -1em;
  color: rgb(70, 76, 85);
}
.ptl-icon-goal-1 .path5:before {
  content: '\ea75';
  margin-left: -1em;
  color: rgb(247, 247, 251);
}
.ptl-icon-goal-1 .path6:before {
  content: '\ea76';
  margin-left: -1em;
  color: rgb(70, 76, 85);
}
.ptl-icon-growth:before {
  content: '\ea7b';
}
.ptl-icon-hamburger-menu .path1:before {
  content: '\ea7f';
  color: rgb(255, 255, 255);
}
.ptl-icon-hamburger-menu .path2:before {
  content: '\ea80';
  margin-left: -1em;
  color: rgb(68, 75, 84);
}
.ptl-icon-hamburger-menu .path3:before {
  content: '\ea81';
  margin-left: -1em;
  color: rgb(68, 75, 84);
}
.ptl-icon-hamburger-menu .path4:before {
  content: '\ea82';
  margin-left: -1em;
  color: rgb(68, 75, 84);
}
.ptl-icon-hamburger-menu1 .path1:before {
  content: '\ea83';
  color: rgb(255, 255, 255);
}
.ptl-icon-hamburger-menu1 .path2:before {
  content: '\ea84';
  margin-left: -1em;
  color: rgb(68, 75, 84);
}
.ptl-icon-hamburger-menu1 .path3:before {
  content: '\ea85';
  margin-left: -1em;
  color: rgb(68, 75, 84);
}
.ptl-icon-hamburger-menu1 .path4:before {
  content: '\ea86';
  margin-left: -1em;
  color: rgb(68, 75, 84);
}
.ptl-icon-handshake .path1:before {
  content: '\ea87';
  color: rgb(255, 255, 255);
}
.ptl-icon-handshake .path2:before {
  content: '\ea88';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.ptl-icon-handshake .path3:before {
  content: '\ea89';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.ptl-icon-handshake .path4:before {
  content: '\ea8a';
  margin-left: -1em;
  color: rgb(68, 75, 84);
}
.ptl-icon-handshake .path5:before {
  content: '\ea8b';
  margin-left: -1em;
  color: rgb(68, 75, 84);
}
.ptl-icon-handshake .path6:before {
  content: '\ea8c';
  margin-left: -1em;
  color: rgb(113, 194, 255);
}
.ptl-icon-handshake .path7:before {
  content: '\ea8d';
  margin-left: -1em;
  color: rgb(113, 194, 255);
}
.ptl-icon-happy-file .path1:before {
  content: '\ea8e';
  color: rgb(255, 255, 255);
}
.ptl-icon-happy-file .path2:before {
  content: '\ea8f';
  margin-left: -1em;
  color: rgb(113, 194, 255);
}
.ptl-icon-happy-file .path3:before {
  content: '\ea90';
  margin-left: -1em;
  color: rgb(68, 75, 84);
}
.ptl-icon-head-with-brain .path1:before {
  content: '\ea91';
  color: rgb(255, 255, 255);
}
.ptl-icon-head-with-brain .path2:before {
  content: '\ea92';
  margin-left: -1em;
  color: rgb(68, 75, 84);
}
.ptl-icon-head-with-brain .path3:before {
  content: '\ea93';
  margin-left: -1em;
  color: rgb(68, 75, 84);
}
.ptl-icon-heart-1:before {
  content: '\ea94';
}
.ptl-icon-home-copy .path1:before {
  content: '\ea95';
  color: rgb(255, 255, 255);
}
.ptl-icon-home-copy .path2:before {
  content: '\ea96';
  margin-left: -1em;
  color: rgb(199, 215, 226);
}
.ptl-icon-home-copy .path3:before {
  content: '\ea97';
  margin-left: -1em;
  color: rgb(199, 215, 226);
}
.ptl-icon-home-copy .path4:before {
  content: '\ea98';
  margin-left: -1em;
  color: rgb(69, 75, 84);
}
.ptl-icon-home-copy .path5:before {
  content: '\ea99';
  margin-left: -1em;
  color: rgb(199, 215, 226);
}
.ptl-icon-home-copy .path6:before {
  content: '\ea9a';
  margin-left: -1em;
  color: rgb(227, 243, 247);
}
.ptl-icon-home-1:before {
  content: '\ea9b';
}
.ptl-icon-indent:before {
  content: '\ea9c';
}
.ptl-icon-innovation-1 .path1:before {
  content: '\ea9d';
  color: rgb(255, 255, 255);
}
.ptl-icon-innovation-1 .path2:before {
  content: '\ea9e';
  margin-left: -1em;
  color: rgb(241, 233, 180);
}
.ptl-icon-innovation-1 .path3:before {
  content: '\ea9f';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.ptl-icon-innovation-1 .path4:before {
  content: '\eaa0';
  margin-left: -1em;
  color: rgb(69, 75, 84);
}
.ptl-icon-innovation-1 .path5:before {
  content: '\eaa1';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.ptl-icon-innovation-1 .path6:before {
  content: '\eaa2';
  margin-left: -1em;
  color: rgb(69, 75, 84);
}
.ptl-icon-insert-row-1:before {
  content: '\eaa3';
}
.ptl-icon-invisible .path1:before {
  content: '\eaa4';
  color: rgb(255, 255, 255);
}
.ptl-icon-invisible .path2:before {
  content: '\eaa5';
  margin-left: -1em;
  color: rgb(68, 75, 84);
}
.ptl-icon-invisible .path3:before {
  content: '\eaa6';
  margin-left: -1em;
  color: rgb(68, 75, 84);
}
.ptl-icon-invisible .path4:before {
  content: '\eaa7';
  margin-left: -1em;
  color: rgb(113, 194, 255);
}
.ptl-icon-invisible .path5:before {
  content: '\eaa8';
  margin-left: -1em;
  color: rgb(68, 75, 84);
}
.ptl-icon-key-2 .path1:before {
  content: '\eaa9';
  color: rgb(255, 255, 255);
}
.ptl-icon-key-2 .path2:before {
  content: '\eaaa';
  margin-left: -1em;
  color: rgb(199, 215, 226);
}
.ptl-icon-key-2 .path3:before {
  content: '\eaab';
  margin-left: -1em;
  color: rgb(173, 187, 198);
}
.ptl-icon-key-2 .path4:before {
  content: '\eaac';
  margin-left: -1em;
  color: rgb(69, 75, 84);
}
.ptl-icon-key-2 .path5:before {
  content: '\eaad';
  margin-left: -1em;
  color: rgb(69, 75, 84);
}
.ptl-icon-key-2 .path6:before {
  content: '\eaae';
  margin-left: -1em;
  color: rgb(69, 75, 84);
}
.ptl-icon-key-2 .path7:before {
  content: '\eaaf';
  margin-left: -1em;
  color: rgb(69, 75, 84);
}
.ptl-icon-left:before {
  content: '\eab0';
}
.ptl-icon-graduation-cap .path1:before {
  content: '\eab1';
  color: rgb(255, 255, 255);
}
.ptl-icon-graduation-cap .path2:before {
  content: '\eab2';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.ptl-icon-graduation-cap .path3:before {
  content: '\eab3';
  margin-left: -1em;
  color: rgb(68, 75, 84);
}
.ptl-icon-graduation-cap .path4:before {
  content: '\eab4';
  margin-left: -1em;
  color: rgb(68, 75, 84);
}
.ptl-icon-link-1:before {
  content: '\eaba';
}
.ptl-icon-memo .path1:before {
  content: '\eabb';
  color: rgb(255, 255, 255);
}
.ptl-icon-memo .path2:before {
  content: '\eabc';
  margin-left: -1em;
  color: rgb(113, 194, 255);
}
.ptl-icon-memo .path3:before {
  content: '\eabd';
  margin-left: -1em;
  color: rgb(68, 75, 84);
}
.ptl-icon-memo .path4:before {
  content: '\eabe';
  margin-left: -1em;
  color: rgb(68, 75, 84);
}
.ptl-icon-memo .path5:before {
  content: '\eabf';
  margin-left: -1em;
  color: rgb(68, 75, 84);
}
.ptl-icon-menu-vertical-1:before {
  content: '\eac0';
}
.ptl-icon-mission1 .path1:before {
  content: '\eac1';
  color: rgb(255, 255, 255);
}
.ptl-icon-mission1 .path2:before {
  content: '\eac2';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.ptl-icon-mission1 .path3:before {
  content: '\eac3';
  margin-left: -1em;
  color: rgb(113, 194, 255);
}
.ptl-icon-mission1 .path4:before {
  content: '\eac4';
  margin-left: -1em;
  color: rgb(68, 75, 84);
}
.ptl-icon-move-grabber-1:before {
  content: '\eac5';
}
.ptl-icon-move-left:before {
  content: '\eac6';
}
.ptl-icon-move-node-down:before {
  content: '\eac7';
}
.ptl-icon-move-node-up:before {
  content: '\eac8';
}
.ptl-icon-news2 .path1:before {
  content: '\eac9';
  color: rgb(247, 247, 251);
}
.ptl-icon-news2 .path2:before {
  content: '\eaca';
  margin-left: -1em;
  color: rgb(222, 223, 230);
}
.ptl-icon-news2 .path3:before {
  content: '\eacb';
  margin-left: -1em;
  color: rgb(70, 76, 85);
}
.ptl-icon-news2 .path4:before {
  content: '\eacc';
  margin-left: -1em;
  color: rgb(222, 223, 230);
}
.ptl-icon-news2 .path5:before {
  content: '\eacd';
  margin-left: -1em;
  color: rgb(70, 76, 85);
}
.ptl-icon-news3 .path1:before {
  content: '\eace';
  color: rgb(113, 194, 255);
}
.ptl-icon-news3 .path2:before {
  content: '\eacf';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.ptl-icon-news3 .path3:before {
  content: '\ead0';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.ptl-icon-news3 .path4:before {
  content: '\ead1';
  margin-left: -1em;
  color: rgb(68, 75, 84);
}
.ptl-icon-news3 .path5:before {
  content: '\ead2';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.ptl-icon-news3 .path6:before {
  content: '\ead3';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.ptl-icon-next-webpage .path1:before {
  content: '\ead4';
  color: rgb(255, 255, 255);
}
.ptl-icon-next-webpage .path2:before {
  content: '\ead5';
  margin-left: -1em;
  color: rgb(68, 75, 84);
}
.ptl-icon-next-webpage .path3:before {
  content: '\ead6';
  margin-left: -1em;
  color: rgb(68, 75, 84);
}
.ptl-icon-next-webpage .path4:before {
  content: '\ead7';
  margin-left: -1em;
  color: rgb(228, 231, 231);
}
.ptl-icon-next-webpage .path5:before {
  content: '\ead8';
  margin-left: -1em;
  color: rgb(228, 231, 231);
}
.ptl-icon-next-webpage .path6:before {
  content: '\ead9';
  margin-left: -1em;
  color: rgb(228, 231, 231);
}
.ptl-icon-next-webpage .path7:before {
  content: '\eada';
  margin-left: -1em;
  color: rgb(68, 75, 84);
}
.ptl-icon-next-webpage .path8:before {
  content: '\eadb';
  margin-left: -1em;
  color: rgb(68, 75, 84);
}
.ptl-icon-next-webpage .path9:before {
  content: '\eadc';
  margin-left: -1em;
  color: rgb(68, 75, 84);
}
.ptl-icon-next-webpage .path10:before {
  content: '\eadd';
  margin-left: -1em;
  color: rgb(252, 202, 61);
}
.ptl-icon-next-webpage .path11:before {
  content: '\eade';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.ptl-icon-note-2 .path1:before {
  content: '\eadf';
  color: rgb(255, 255, 255);
}
.ptl-icon-note-2 .path2:before {
  content: '\eae0';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.ptl-icon-note-2 .path3:before {
  content: '\eae1';
  margin-left: -1em;
  color: rgb(113, 194, 255);
}
.ptl-icon-note-2 .path4:before {
  content: '\eae2';
  margin-left: -1em;
  color: rgb(113, 194, 255);
}
.ptl-icon-note-2 .path5:before {
  content: '\eae3';
  margin-left: -1em;
  color: rgb(68, 75, 84);
}
.ptl-icon-notification-1:before {
  content: '\eae4';
}
.ptl-icon-open-document .path1:before {
  content: '\eaea';
  color: rgb(255, 255, 255);
}
.ptl-icon-open-document .path2:before {
  content: '\eaeb';
  margin-left: -1em;
  color: rgb(113, 194, 255);
}
.ptl-icon-open-document .path3:before {
  content: '\eaec';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.ptl-icon-open-document .path4:before {
  content: '\eaed';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.ptl-icon-open-document .path5:before {
  content: '\eaee';
  margin-left: -1em;
  color: rgb(68, 75, 84);
}
.ptl-icon-open-document .path6:before {
  content: '\eaef';
  margin-left: -1em;
  color: rgb(68, 75, 84);
}
.ptl-icon-page-1 .path1:before {
  content: '\eaf0';
  color: rgb(255, 255, 255);
}
.ptl-icon-page-1 .path2:before {
  content: '\eaf1';
  margin-left: -1em;
  color: rgb(68, 75, 84);
}
.ptl-icon-page-1 .path3:before {
  content: '\eaf2';
  margin-left: -1em;
  color: rgb(211, 216, 221);
}
.ptl-icon-pencil .path1:before {
  content: '\eaf3';
  color: rgb(68, 75, 84);
}
.ptl-icon-pencil .path2:before {
  content: '\eaf4';
  margin-left: -1em;
  color: rgb(68, 75, 84);
}
.ptl-icon-pencil .path3:before {
  content: '\eaf5';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.ptl-icon-pencil .path4:before {
  content: '\eaf6';
  margin-left: -1em;
  color: rgb(113, 194, 255);
}
.ptl-icon-pencil .path5:before {
  content: '\eaf7';
  margin-left: -1em;
  color: rgb(68, 75, 84);
}
.ptl-icon-vintage-glasses .path1:before {
  content: '\eaf8';
  color: rgb(113, 194, 255);
}
.ptl-icon-vintage-glasses .path2:before {
  content: '\eaf9';
  margin-left: -1em;
  color: rgb(113, 194, 255);
}
.ptl-icon-vintage-glasses .path3:before {
  content: '\eafa';
  margin-left: -1em;
  color: rgb(68, 75, 84);
}
.ptl-icon-vintage-glasses .path4:before {
  content: '\eafb';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.ptl-icon-vintage-glasses .path5:before {
  content: '\eafc';
  margin-left: -1em;
  color: rgb(68, 75, 84);
}
.ptl-icon-vintage-glasses .path6:before {
  content: '\eafd';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.ptl-icon-vintage-glasses .path7:before {
  content: '\eafe';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.ptl-icon-iresponsive:before {
  content: '\eaff';
}
.ptl-icon-retro-alarm-clock .path1:before {
  content: '\eb00';
  color: rgb(68, 75, 84);
}
.ptl-icon-retro-alarm-clock .path2:before {
  content: '\eb01';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.ptl-icon-retro-alarm-clock .path3:before {
  content: '\eb02';
  margin-left: -1em;
  color: rgb(173, 249, 210);
}
.ptl-icon-retro-alarm-clock .path4:before {
  content: '\eb03';
  margin-left: -1em;
  color: rgb(68, 75, 84);
}
.ptl-icon-retro-alarm-clock .path5:before {
  content: '\eb04';
  margin-left: -1em;
  color: rgb(68, 75, 84);
}
.ptl-icon-route .path1:before {
  content: '\eb05';
  color: rgb(255, 255, 255);
}
.ptl-icon-route .path2:before {
  content: '\eb06';
  margin-left: -1em;
  color: rgb(69, 75, 84);
}
.ptl-icon-route .path3:before {
  content: '\eb07';
  margin-left: -1em;
  color: rgb(170, 143, 157);
}
.ptl-icon-route .path4:before {
  content: '\eb08';
  margin-left: -1em;
  color: rgb(69, 75, 84);
}
.ptl-icon-route .path5:before {
  content: '\eb09';
  margin-left: -1em;
  color: rgb(244, 136, 132);
}
.ptl-icon-search:before {
  content: '\eb0a';
}
.ptl-icon-settings-1:before {
  content: '\eb0e';
}
.ptl-icon-share-1 .path1:before {
  content: '\eb11';
  color: rgb(237, 236, 242);
}
.ptl-icon-share-1 .path2:before {
  content: '\eb12';
  margin-left: -1em;
  color: rgb(237, 229, 122);
}
.ptl-icon-share-1 .path3:before {
  content: '\eb13';
  margin-left: -1em;
  color: rgb(248, 244, 178);
}
.ptl-icon-share-1 .path4:before {
  content: '\eb14';
  margin-left: -1em;
  color: rgb(70, 76, 85);
}
.ptl-icon-share-1 .path5:before {
  content: '\eb15';
  margin-left: -1em;
  color: rgb(70, 76, 85);
}
.ptl-icon-share-1 .path6:before {
  content: '\eb16';
  margin-left: -1em;
  color: rgb(70, 76, 85);
}
.ptl-icon-subfolder:before {
  content: '\eb17';
}
.ptl-icon-sunny-side-up-eggs .path1:before {
  content: '\eb18';
  color: rgb(255, 255, 255);
}
.ptl-icon-sunny-side-up-eggs .path2:before {
  content: '\eb19';
  margin-left: -1em;
  color: rgb(255, 208, 57);
}
.ptl-icon-sunny-side-up-eggs .path3:before {
  content: '\eb1a';
  margin-left: -1em;
  color: rgb(229, 187, 51);
}
.ptl-icon-sunny-side-up-eggs .path4:before {
  content: '\eb1b';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.ptl-icon-sunny-side-up-eggs .path5:before {
  content: '\eb1c';
  margin-left: -1em;
  color: rgb(69, 75, 84);
}
.ptl-icon-surprise .path1:before {
  content: '\eb1d';
  color: rgb(255, 255, 255);
}
.ptl-icon-surprise .path2:before {
  content: '\eb1e';
  margin-left: -1em;
  color: rgb(113, 194, 255);
}
.ptl-icon-surprise .path3:before {
  content: '\eb1f';
  margin-left: -1em;
  color: rgb(68, 75, 84);
}
.ptl-icon-surprise .path4:before {
  content: '\eb20';
  margin-left: -1em;
  color: rgb(68, 75, 84);
}
.ptl-icon-surprise .path5:before {
  content: '\eb21';
  margin-left: -1em;
  color: rgb(68, 75, 84);
}
.ptl-icon-surprise .path6:before {
  content: '\eb22';
  margin-left: -1em;
  color: rgb(68, 75, 84);
}
.ptl-icon-surprise .path7:before {
  content: '\eb23';
  margin-left: -1em;
  color: rgb(68, 75, 84);
}
.ptl-icon-surprise .path8:before {
  content: '\eb24';
  margin-left: -1em;
  color: rgb(68, 75, 84);
}
.ptl-icon-surprise .path9:before {
  content: '\eb25';
  margin-left: -1em;
  color: rgb(68, 75, 84);
}
.ptl-icon-text-input-form-1:before {
  content: '\eb26';
}
.ptl-icon-toolbox-1 .path1:before {
  content: '\eb27';
  color: rgb(255, 255, 255);
}
.ptl-icon-toolbox-1 .path2:before {
  content: '\eb28';
  margin-left: -1em;
  color: rgb(228, 202, 166);
}
.ptl-icon-toolbox-1 .path3:before {
  content: '\eb29';
  margin-left: -1em;
  color: rgb(182, 162, 133);
}
.ptl-icon-toolbox-1 .path4:before {
  content: '\eb2a';
  margin-left: -1em;
  color: rgb(251, 229, 205);
}
.ptl-icon-toolbox-1 .path5:before {
  content: '\eb2b';
  margin-left: -1em;
  color: rgb(69, 75, 84);
}
.ptl-icon-toolbox-1 .path6:before {
  content: '\eb2c';
  margin-left: -1em;
  color: rgb(69, 75, 84);
}
.ptl-icon-toolbox-1 .path7:before {
  content: '\eb2d';
  margin-left: -1em;
  color: rgb(69, 75, 84);
}
.ptl-icon-toolbox-1 .path8:before {
  content: '\eb2e';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.ptl-icon-toolbox-1 .path9:before {
  content: '\eb2f';
  margin-left: -1em;
  color: rgb(69, 75, 84);
}
.ptl-icon-user-groups .path1:before {
  content: '\eb30';
  color: rgb(255, 255, 255);
}
.ptl-icon-user-groups .path2:before {
  content: '\eb31';
  margin-left: -1em;
  color: rgb(113, 194, 255);
}
.ptl-icon-user-groups .path3:before {
  content: '\eb32';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.ptl-icon-user-groups .path4:before {
  content: '\eb33';
  margin-left: -1em;
  color: rgb(113, 194, 255);
}
.ptl-icon-user-groups .path5:before {
  content: '\eb34';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.ptl-icon-user-groups .path6:before {
  content: '\eb35';
  margin-left: -1em;
  color: rgb(68, 75, 84);
}
.ptl-icon-plus-1:before {
  content: '\eb36';
}
.ptl-icon-photo-gallery .path1:before {
  content: '\eb37';
  color: rgb(255, 255, 255);
}
.ptl-icon-photo-gallery .path2:before {
  content: '\eb38';
  margin-left: -1em;
  color: rgb(68, 75, 84);
}
.ptl-icon-photo-gallery .path3:before {
  content: '\eb39';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.ptl-icon-photo-gallery .path4:before {
  content: '\eb3a';
  margin-left: -1em;
  color: rgb(195, 219, 234);
}
.ptl-icon-photo-gallery .path5:before {
  content: '\eb3b';
  margin-left: -1em;
  color: rgb(68, 75, 84);
}
.ptl-icon-photo-gallery .path6:before {
  content: '\eb3c';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.ptl-icon-photo-gallery .path7:before {
  content: '\eb3d';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.ptl-icon-photo-gallery .path8:before {
  content: '\eb3e';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.ptl-icon-photo-gallery-copy .path1:before {
  content: '\eb3f';
  color: rgb(255, 255, 255);
}
.ptl-icon-photo-gallery-copy .path2:before {
  content: '\eb40';
  margin-left: -1em;
  color: rgb(68, 75, 84);
}
.ptl-icon-photo-gallery-copy .path3:before {
  content: '\eb41';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.ptl-icon-photo-gallery-copy .path4:before {
  content: '\eb42';
  margin-left: -1em;
  color: rgb(195, 219, 234);
}
.ptl-icon-photo-gallery-copy .path5:before {
  content: '\eb43';
  margin-left: -1em;
  color: rgb(68, 75, 84);
}
.ptl-icon-photo-gallery-copy .path6:before {
  content: '\eb44';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.ptl-icon-photo-gallery-copy .path7:before {
  content: '\eb45';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.ptl-icon-photo-gallery-copy .path8:before {
  content: '\eb46';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.ptl-icon-personal-growth-1 .path1:before {
  content: '\eb47';
  color: rgb(255, 255, 255);
}
.ptl-icon-personal-growth-1 .path2:before {
  content: '\eb48';
  margin-left: -1em;
  color: rgb(113, 194, 255);
}
.ptl-icon-personal-growth-1 .path3:before {
  content: '\eb49';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.ptl-icon-personal-growth-1 .path4:before {
  content: '\eb4a';
  margin-left: -1em;
  color: rgb(68, 75, 84);
}
.ptl-icon-personal-growth-1 .path5:before {
  content: '\eb4b';
  margin-left: -1em;
  color: rgb(113, 194, 255);
}
.ptl-icon-clock-1:before {
  content: '\e9f9';
}
.ptl-icon-add-image:before {
  content: '\e9f8';
}
.ptl-icon-crown:before {
  content: '\e9f6';
}
.ptl-icon-crown-outlined:before {
  content: '\e9f7';
}
.ptl-icon-text:before {
  content: '\e9f4';
}
.ptl-icon-blockly-light-green:before {
  content: '\e9f5';
}
.ptl-icon-abacus:before {
  content: '\e900';
}
.ptl-icon-abstract:before {
  content: '\e901';
}
.ptl-icon-activity-history:before {
  content: '\e902';
}
.ptl-icon-add-file:before {
  content: '\e903';
}
.ptl-icon-add-link:before {
  content: '\e904';
}
.ptl-icon-add-row:before {
  content: '\e905';
}
.ptl-icon-add-row2:before {
  content: '\e906';
}
.ptl-icon-add-text:before {
  content: '\e907';
}
.ptl-icon-add-to-favorites:before {
  content: '\e908';
}
.ptl-icon-administrative-tools:before {
  content: '\e909';
}
.ptl-icon-admin-settings-male:before {
  content: '\e90a';
}
.ptl-icon-advice:before {
  content: '\e90b';
}
.ptl-icon-alpha:before {
  content: '\e90c';
}
.ptl-icon-analyzing-skill:before {
  content: '\e90d';
}
.ptl-icon-application-window:before {
  content: '\e90e';
}
.ptl-icon-asterisk:before {
  content: '\e90f';
}
.ptl-icon-asteriskfilled:before {
  content: '\e910';
}
.ptl-icon-attach:before {
  content: '\e911';
}
.ptl-icon-audience:before {
  content: '\e912';
}
.ptl-icon-back:before {
  content: '\e913';
  color: #444b54;
}
.ptl-icon-big-ben:before {
  content: '\e914';
}
.ptl-icon-binoculars:before {
  content: '\e915';
}
.ptl-icon-bookmark:before {
  content: '\e916';
}
.ptl-icon-bookmarkiOS:before {
  content: '\e917';
}
.ptl-icon-brain:before {
  content: '\e918';
}
.ptl-icon-brainstorm-skill:before {
  content: '\e919';
}
.ptl-icon-brainstorm-skill4:before {
  content: '\e91a';
}
.ptl-icon-branding:before {
  content: '\e91b';
}
.ptl-icon-brick-wall:before {
  content: '\e91c';
}
.ptl-icon-business-report:before {
  content: '\e91d';
}
.ptl-icon-calendar:before {
  content: '\e91e';
}
.ptl-icon-calendar-12:before {
  content: '\e91f';
}
.ptl-icon-calendarIos:before {
  content: '\e920';
}
.ptl-icon-calendarios2:before {
  content: '\e921';
}
.ptl-icon-call-to-action:before {
  content: '\e922';
}
.ptl-icon-career-ladder:before {
  content: '\e923';
}
.ptl-icon-categorize:before {
  content: '\e924';
}
.ptl-icon-certificate:before {
  content: '\e925';
}
.ptl-icon-change-theme:before {
  content: '\e926';
}
.ptl-icon-checkbox:before {
  content: '\e927';
}
.ptl-icon-checked-checkbox:before {
  content: '\e928';
}
.ptl-icon-checkmark:before {
  content: '\e929';
}
.ptl-icon-checkmark-2:before {
  content: '\e92a';
}
.ptl-icon-clock:before {
  content: '\e92b';
}
.ptl-icon-commercial:before {
  content: '\e92c';
}
.ptl-icon-communication:before {
  content: '\e92d';
}
.ptl-icon-compose:before {
  content: '\e92e';
}
.ptl-icon-computers-connecting:before {
  content: '\e92f';
}
.ptl-icon-connected:before {
  content: '\e930';
}
.ptl-icon-connecteda:before {
  content: '\e931';
}
.ptl-icon-contact-us:before {
  content: '\e932';
}
.ptl-icon-contract:before {
  content: '\e933';
}
.ptl-icon-copy:before {
  content: '\e934';
}
.ptl-icon-copypaste:before {
  content: '\e935';
}
.ptl-icon-creativity:before {
  content: '\e936';
}
.ptl-icon-dashboard-layout:before {
  content: '\e937';
}
.ptl-icon-dashboard-layout24px:before {
  content: '\e938';
}
.ptl-icon-database-export:before {
  content: '\e939';
}
.ptl-icon-date-to:before {
  content: '\e93a';
}
.ptl-icon-diploma:before {
  content: '\e93b';
}
.ptl-icon-documents-folder:before {
  content: '\e93c';
}
.ptl-icon-down-arrow:before {
  content: '\e93d';
}
.ptl-icon-download:before {
  content: '\e93e';
}
.ptl-icon-duplicate:before {
  content: '\e93f';
}
.ptl-icon-easy:before {
  content: '\e940';
}
.ptl-icon-edgar-allan-poe:before {
  content: '\e941';
}
.ptl-icon-edit:before {
  content: '\e942';
}
.ptl-icon-edit-file:before {
  content: '\e943';
}
.ptl-icon-edit-row:before {
  content: '\e944';
}
.ptl-icon-e-learning:before {
  content: '\e945';
}
.ptl-icon-ellipsis:before {
  content: '\e946';
}
.ptl-icon-empty-flag:before {
  content: '\e947';
}
.ptl-icon-empty-flag2:before {
  content: '\e948';
}
.ptl-icon-envelope:before {
  content: '\e949';
}
.ptl-icon-espresso-cup:before {
  content: '\e94a';
}
.ptl-icon-event:before {
  content: '\e94b';
}
.ptl-icon-event-2:before {
  content: '\e94c';
}
.ptl-icon-export-pdf:before {
  content: '\e94d';
}
.ptl-icon-facebook-f:before {
  content: '\e94e';
}
.ptl-icon-facebook-like:before {
  content: '\e94f';
}
.ptl-icon-facebook-old:before {
  content: '\e950';
}
.ptl-icon-faq:before {
  content: '\e951';
}
.ptl-icon-filing-cabinet:before {
  content: '\e952';
}
.ptl-icon-flag-filled:before {
  content: '\e953';
}
.ptl-icon-forward-arrow:before {
  content: '\e954';
}
.ptl-icon-globe:before {
  content: '\e955';
}
.ptl-icon-goal:before {
  content: '\e956';
}
.ptl-icon-google-keep:before {
  content: '\e957';
}
.ptl-icon-gps:before {
  content: '\e958';
}
.ptl-icon-group:before {
  content: '\e959';
}
.ptl-icon-group-task:before {
  content: '\e95a';
}
.ptl-icon-hand-cursor:before {
  content: '\e95b';
}
.ptl-icon-hand-peace:before {
  content: '\e95c';
}
.ptl-icon-hand-with-pen:before {
  content: '\e95d';
}
.ptl-icon-heart:before {
  content: '\e95e';
}
.ptl-icon-hello:before {
  content: '\e95f';
}
.ptl-icon-help:before {
  content: '\e960';
}
.ptl-icon-hierarchy:before {
  content: '\e961';
}
.ptl-icon-home:before {
  content: '\e962';
}
.ptl-icon-home-page:before {
  content: '\e963';
}
.ptl-icon-hot-air-balloon:before {
  content: '\e964';
}
.ptl-icon-idea:before {
  content: '\e965';
}
.ptl-icon-image:before {
  content: '\e966';
}
.ptl-icon-important-mail:before {
  content: '\e967';
}
.ptl-icon-index:before {
  content: '\e968';
}
.ptl-icon-info:before {
  content: '\e969';
}
.ptl-icon-innovation:before {
  content: '\e96a';
}
.ptl-icon-in-progress:before {
  content: '\e96b';
}
.ptl-icon-insert-row:before {
  content: '\e96c';
}
.ptl-icon-inspection:before {
  content: '\e96d';
}
.ptl-icon-instagram:before {
  content: '\e96e';
}
.ptl-icon-invite:before {
  content: '\e96f';
}
.ptl-icon-journey:before {
  content: '\e970';
}
.ptl-icon-language:before {
  content: '\e971';
}
.ptl-icon-launchpad:before {
  content: '\e972';
}
.ptl-icon-leadership:before {
  content: '\e973';
}
.ptl-icon-leaf:before {
  content: '\e974';
}
.ptl-icon-learn-more:before {
  content: '\e975';
}
.ptl-icon-link:before {
  content: '\e976';
}
.ptl-icon-linkedin:before {
  content: '\e977';
}
.ptl-icon-linkedin-2:before {
  content: '\e978';
}
.ptl-icon-list:before {
  content: '\e979';
}
.ptl-icon-list-2:before {
  content: '\e97a';
}
.ptl-icon-loading-bar:before {
  content: '\e97b';
}
.ptl-icon-location:before {
  content: '\e97c';
}
.ptl-icon-locationios:before {
  content: '\e97d';
}
.ptl-icon-log:before {
  content: '\e97e';
}
.ptl-icon-logout-rounded-down:before {
  content: '\e97f';
}
.ptl-icon-medal:before {
  content: '\e980';
}
.ptl-icon-medium-monogram:before {
  content: '\e981';
}
.ptl-icon-megaphone:before {
  content: '\e982';
}
.ptl-icon-menu-vertical:before {
  content: '\e983';
}
.ptl-icon-menu-verticald:before {
  content: '\e984';
}
.ptl-icon-merge-docunemts:before {
  content: '\e985';
}
.ptl-icon-merge-horizontal:before {
  content: '\e986';
}
.ptl-icon-merge-horizontal-2:before {
  content: '\e987';
}
.ptl-icon-mind-map:before {
  content: '\e988';
}
.ptl-icon-mind-map2:before {
  content: '\e989';
}
.ptl-icon-mission:before {
  content: '\e98a';
}
.ptl-icon-more:before {
  content: '\e98b';
}
.ptl-icon-more-details:before {
  content: '\e98c';
}
.ptl-icon-move-grabber:before {
  content: '\e98d';
}
.ptl-icon-music-video:before {
  content: '\e98e';
}
.ptl-icon-news:before {
  content: '\e98f';
}
.ptl-icon-news-cute:before {
  content: '\e990';
}
.ptl-icon-new-store:before {
  content: '\e991';
}
.ptl-icon-new-ticket:before {
  content: '\e992';
}
.ptl-icon-next:before {
  content: '\e993';
}
.ptl-icon-note:before {
  content: '\e994';
}
.ptl-icon-notification:before {
  content: '\e995';
}
.ptl-icon-numbered-list:before {
  content: '\e996';
}
.ptl-icon-oak-tree:before {
  content: '\e997';
}
.ptl-icon-online-support:before {
  content: '\e998';
}
.ptl-icon-open-door:before {
  content: '\e999';
}
.ptl-icon-organization:before {
  content: '\e99a';
}
.ptl-icon-organization-chart-people:before {
  content: '\e99b';
}
.ptl-icon-page:before {
  content: '\e99c';
}
.ptl-icon-paper:before {
  content: '\e99d';
}
.ptl-icon-paste-as-text:before {
  content: '\e99e';
}
.ptl-icon-path:before {
  content: '\e99f';
}
.ptl-icon-people:before {
  content: '\e9a0';
}
.ptl-icon-personal-growth:before {
  content: '\e9a1';
}
.ptl-icon-placeholder-thumbnail-document:before {
  content: '\e9a2';
}
.ptl-icon-play:before {
  content: '\e9a3';
}
.ptl-icon-play-button-circled:before {
  content: '\e9a4';
}
.ptl-icon-playfilled:before {
  content: '\e9a5';
}
.ptl-icon-play-property:before {
  content: '\e9a6';
}
.ptl-icon-plus:before {
  content: '\e9a7';
}
.ptl-icon-portfolio:before {
  content: '\e9a8';
}
.ptl-icon-print:before {
  content: '\e9a9';
}
.ptl-icon-profiles:before {
  content: '\e9aa';
}
.ptl-icon-project-management:before {
  content: '\e9ab';
}
.ptl-icon-puzzle:before {
  content: '\e9ac';
}
.ptl-icon-query-inner-join:before {
  content: '\e9ad';
}
.ptl-icon-question-mark:before {
  content: '\e9ae';
}
.ptl-icon-ratings:before {
  content: '\e9af';
}
.ptl-icon-refresh:before {
  content: '\e9b0';
}
.ptl-icon-refresh-2:before {
  content: '\e9b1';
}
.ptl-icon-reply-arrow:before {
  content: '\e9b2';
}
.ptl-icon-return:before {
  content: '\e9b3';
}
.ptl-icon-rocket:before {
  content: '\e9b4';
}
.ptl-icon-sailing-ship:before {
  content: '\e9b5';
}
.ptl-icon-search-more:before {
  content: '\e9b6';
}
.ptl-icon-select-column:before {
  content: '\e9b7';
}
.ptl-icon-send-file:before {
  content: '\e9b8';
}
.ptl-icon-settings-ios:before {
  content: '\e9b9';
}
.ptl-icon-settings:before {
  content: '\e9ba';
}
.ptl-icon-settings-2:before {
  content: '\e9bb';
}
.ptl-icon-share:before {
  content: '\e9bc';
}
.ptl-icon-share2:before {
  content: '\e9bd';
}
.ptl-icon-share-2:before {
  content: '\e9be';
}
.ptl-icon-shop:before {
  content: '\e9bf';
}
.ptl-icon-slider:before {
  content: '\e9c0';
}
.ptl-icon-sound:before {
  content: '\e9c1';
}
.ptl-icon-split:before {
  content: '\e9c2';
}
.ptl-icon-sprout:before {
  content: '\e9c3';
}
.ptl-icon-startbw:before {
  content: '\e9c4';
}
.ptl-icon-storytelling:before {
  content: '\e9c5';
}
.ptl-icon-street-view:before {
  content: '\e9c6';
}
.ptl-icon-study:before {
  content: '\e9c7';
}
.ptl-icon-submit-document:before {
  content: '\e9c8';
}
.ptl-icon-submit-for-approval:before {
  content: '\e9c9';
}
.ptl-icon-sun:before {
  content: '\e9ca';
}
.ptl-icon-team:before {
  content: '\e9cb';
}
.ptl-icon-teamwork:before {
  content: '\e9cc';
}
.ptl-icon-template:before {
  content: '\e9cd';
}
.ptl-icon-test:before {
  content: '\e9ce';
}
.ptl-icon-test-account:before {
  content: '\e9cf';
}
.ptl-icon-text-input-form:before {
  content: '\e9d0';
}
.ptl-icon-thumbs-up:before {
  content: '\e9d1';
}
.ptl-icon-thumbs-up-2:before {
  content: '\e9d2';
}
.ptl-icon-thumbs-upIOS:before {
  content: '\e9d3';
}
.ptl-icon-timezone:before {
  content: '\e9d4';
}
.ptl-icon-toolbox:before {
  content: '\e9d5';
}
.ptl-icon-train-track:before {
  content: '\e9d6';
}
.ptl-icon-trash:before {
  content: '\e9d7';
}
.ptl-icon-trash-can:before {
  content: '\e9d8';
}
.ptl-icon-treasure-map:before {
  content: '\e9d9';
}
.ptl-icon-treehouse:before {
  content: '\e9da';
}
.ptl-icon-trophy:before {
  content: '\e9db';
}
.ptl-icon-true-false:before {
  content: '\e9dc';
}
.ptl-icon-twitter:before {
  content: '\e9dd';
}
.ptl-icon-ungroup-objects:before {
  content: '\e9de';
}
.ptl-icon-upload:before {
  content: '\e9df';
}
.ptl-icon-upload-document:before {
  content: '\e9e0';
}
.ptl-icon-upload-to-cloud:before {
  content: '\e9e1';
}
.ptl-icon-user:before {
  content: '\e9e2';
}
.ptl-icon-video:before {
  content: '\e9e3';
}
.ptl-icon-video-conference:before {
  content: '\e9e4';
}
.ptl-icon-visualization-skill:before {
  content: '\e9e5';
}
.ptl-icon-volunteering:before {
  content: '\e9e6';
}
.ptl-icon-wavy-line:before {
  content: '\e9e7';
}
.ptl-icon-waypoint-map:before {
  content: '\e9e8';
}
.ptl-icon-webinar:before {
  content: '\e9e9';
}
.ptl-icon-website:before {
  content: '\e9ea';
}
.ptl-icon-welfare:before {
  content: '\e9eb';
}
.ptl-icon-whatsapp:before {
  content: '\e9ec';
}
.ptl-icon-william-shakespeare:before {
  content: '\e9ed';
}
.ptl-icon-work-boot:before {
  content: '\e9ee';
}
.ptl-icon-youtube:before {
  content: '\e9ef';
}
.ptl-icon-youtube-squared:before {
  content: '\e9f0';
}
.ptl-icon-kingdom-87:before {
  content: '\e9f1';
}
.ptl-icon-link1:before {
  content: '\e9f2';
}
.ptl-icon-text-input-form1:before {
  content: '\e9f3';
}

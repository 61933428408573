@font-face {
  font-family: 'charter-regular';
  src:
    url('charter-regular-font.woff2') format('woff2'),
    url('charter-regular-font.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'charter-black';
  src:
    url('charter-black-font.woff2') format('woff2'),
    url('charter-black-font.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'charter-black-italic';
  src:
    url('charter-black-italic-font.woff2') format('woff2'),
    url('charter-black-italic-font.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'charter-bold';
  src:
    url('charter-bold-font.woff2') format('woff2'),
    url('charter-bold-font.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'charter-bold-italic';
  src:
    url('charter-bold-italic-font.woff2') format('woff2'),
    url('charter-bold-italic-font.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'charter-italic';
  src:
    url('charter-italic-font.woff2') format('woff2'),
    url('charter-italic-font.woff') format('woff');
  font-display: swap;
}

@import 'variables';
@import 'breakpoints';
@import 'colors';
@import 'rem';

@mixin side-nav($bgColor: $color--alabaster-light) {
  width: rem(255);
  background-color: $bgColor;
  flex: 1;
  overflow: hidden;
  @include ptl-direction(border, right, rem(1) solid $bgColor);

  &:hover {
    border-right-color: rgba(0, 0, 0, 0.12);
  }

  &:not(:hover) {
    ::ng-deep {
      .mat-drawer-inner-container {
        &::-webkit-scrollbar {
          background-color: $bgColor;
          border-color: $bgColor;
        }

        &::-webkit-scrollbar-track {
          background-color: $bgColor;
          border-color: $bgColor;
        }

        &::-webkit-scrollbar-thumb {
          background-color: $bgColor;
          border-color: $bgColor;
        }
      }
    }
  }

  &.has-action-bar {
    height: 100%;

    & ~ .main__content {
      padding-bottom: rem(70);
      min-height: calc(100vh - rem(165));
    }
  }

  &--with-footer {
    padding-bottom: rem(50);
  }
}

@mixin main-content {
  margin-top: rem(65);
  padding: rem(23) rem(45) rem(40);
  flex: 1;
  min-height: calc(100vh - rem(135));
  min-width: 0;
  z-index: 3;

  @include breakpoint(laptop) {
    padding: rem(23) rem(20) rem(20);
  }

  @include breakpoint(mobileOnly) {
    padding: rem(15) rem(5);
    z-index: 2;
  }
}

@mixin edit-main-content {
  @include ptl-direction(margin, left, rem(240) !important);
  @include ptl-direction(margin, right, rem(0) !important);
  padding: rem(125) rem(45) rem(20);
  min-height: calc(100vh - rem(154));
  min-width: 0;
  overflow: unset;
  z-index: 3;
  outline: none;

  @include breakpoint(laptop) {
    padding: rem(125) rem(20) rem(20);
  }

  @include breakpoint(mobileOnly) {
    @include ptl-direction(margin, left, rem(0) !important);
    padding: rem(15) rem(5);
    margin-top: rem(104);
    min-height: calc(100vh - rem(144));
    z-index: 1;
  }
}

@mixin side-nav-edit($bgColor: $g-color9) {
  width: rem(240);
  background-color: $bgColor;
  border: none;
  height: calc(100% - rem(114));

  @include breakpoint(mobileOnly) {
    min-height: calc(100% - rem(144));
    height: 100%;
  }

  &.mat-drawer-container-has-open {
    z-index: 5;
    background: transparent;
  }

  ::ng-deep {
    .mat-drawer-inner-container {
      padding-top: rem(16);
      padding-bottom: rem(16);
      box-sizing: border-box;
    }
  }
}

@mixin side-nav-tree($bgColor: $g-color9) {
  display: flex;

  &.mat-drawer-container-has-open {
    z-index: 5;
    background: transparent;
  }

  &.is_edit-mode {
    ::ng-deep {
      & > .mat-drawer-backdrop {
        display: none;
      }
    }

    .main__side-nav {
      display: none;
    }

    .main__content {
      @include ptl-direction(margin, left, rem(0) !important);
      margin-top: 0;
      padding: 0;
      min-height: calc(100vh - rem(114));

      @include breakpoint(mobileOnly) {
        z-index: 5;
      }
    }

    &.workspace-hidden {
      overflow: visible;

      .main__side-nav {
        width: 0;
      }

      .main__content {
        @include ptl-direction(margin, left, rem(0) !important);
        overflow: visible;
      }
    }
  }

  .main__side-nav {
    width: rem(298);
    background-color: $bgColor;
    flex: 1;
    overflow-x: hidden;
    height: calc(100% - rem(48));
    box-sizing: border-box;
    @include ptl-direction(border, right, none);

    @include breakpoint(mobileOnly) {
      @include ptl-direction(padding, left, rem(58));
      width: rem(335);
      @include ptl-direction(position, left, rem(0));
      height: 100%;
      z-index: 5;
    }

    @media screen and (max-width: rem(359)) {
      width: rem(298);
    }

    &.wider {
      @include breakpoint(desktop) {
        width: auto;
        max-width: rem(350);
      }
    }

    &:not(:hover) {
      ::ng-deep {
        .mat-drawer-inner-container {
          &::-webkit-scrollbar {
            background-color: $bgColor;
            border-color: $bgColor;
          }

          &::-webkit-scrollbar-track {
            background-color: $bgColor;
            border-color: $bgColor;
          }

          &::-webkit-scrollbar-thumb {
            background-color: $bgColor;
            border-color: $bgColor;
          }
        }
      }
    }

    &.has-action-bar {
      height: calc(100% - rem(120));

      @include breakpoint(mobileOnly) {
        height: 100%;
      }

      & ~ .main__content {
        padding-bottom: rem(70);
      }
    }

    &--with-footer {
      padding-bottom: rem(50);
    }
  }

  .main__side-nav-list {
    @include ptl-direction(margin, left, rem(58));
    padding-top: rem(16);
    height: calc(100% - rem(16));

    @include breakpoint(mobileOnly) {
      @include ptl-direction(margin, left, rem(0));
    }
  }

  .main__content {
    @include ptl-direction(margin, left, rem(298) !important);
    @include ptl-direction(margin, right, rem(0) !important);
    margin-top: rem(65);
    padding: rem(23) rem(45) rem(40);
    flex: 1;
    z-index: 3;
    min-height: calc(100vh - rem(135));
    min-width: 0;
    outline: none;
    overflow: visible;

    &.wider {
      @include breakpoint(desktop) {
        @include ptl-direction(margin, left, rem(350) !important);
      }
    }

    @include breakpoint(aboveMobile) {
      transition: none;
    }

    @include breakpoint(mobileOnly) {
      padding: rem(15) rem(5);
      z-index: 1;
      @include ptl-direction(margin, left, rem(0) !important);
    }
  }
}

@mixin skeleton-animation {
  animation: skeletonAnimation 2.5s ease infinite;
  background-image: linear-gradient(to right, #eaeae9, #dedcdc, #d2d0d0, #c5c4c4);
  background-size: 400% 400%;
}

@keyframes skeletonAnimation {
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}

@mixin text-input {
  padding: rem(8) rem(12);
  border-radius: rem(4);
  font-size: rem(14);
  box-shadow: none;
  line-height: rem(18);
  box-sizing: border-box;
  margin-top: 0;
  border: rem(1) solid $g-color6;
  caret-color: $color--black;

  &:focus {
    outline: none;
    border-color: $g-color11;
  }

  &.has-error {
    box-shadow: none;
    border: rem(1) solid $color--red;

    &:focus {
      border-color: $g-color11;
    }
  }
}

@mixin text-input-form-field() {
  vertical-align: middle;
  width: 100%;
  max-width: 100%;

  &.mat-focused {
    ::ng-deep {
      .mat-input-element {
        caret-color: $g-color11;
      }

      .mat-form-field-flex {
        color: $g-color11;
      }

      .mat-form-field-outline {
        color: $g-color11 !important;
      }
    }
  }

  ::ng-deep {
    .mat-form-field-infix {
      padding: rem(7) rem(5);
      border-top-width: rem(4);
      margin: 0;
    }

    .mat-form-field-wrapper {
      padding-bottom: 0;

      .mat-input-element {
        font-size: rem(12);
        color: initial;
        line-height: rem(24);
      }
    }

    .mat-input-element {
      caret-color: $g-color6;
      color: $g-color1;
    }

    .mat-form-field-flex {
      color: $g-color1;
    }

    .mat-form-field-outline {
      color: $g-color6 !important;

      div {
        border-width: rem(1) !important;
      }
    }

    .mat-form-field-prefix,
    .mat-form-field-suffix {
      top: 0 !important;
    }
  }
}

@mixin select-box {
  padding: rem(8) rem(12);
  border-radius: rem(4);
  font-size: rem(14);
  box-shadow: none;
  box-sizing: border-box;
  line-height: rem(18);
  border: rem(1) solid $g-color6;

  &:focus {
    outline: none;
    border-color: $g-color11;
  }

  &.has-error {
    box-shadow: none;
    border: rem(1) solid $g-color20;

    &:focus {
      border-color: $g-color11;
    }
  }
}

@mixin textarea {
  resize: none;
  box-sizing: border-box;
  padding: rem(8) rem(12);
  border-radius: rem(4);
  font-weight: 300;
  font-size: rem(14);
  border: rem(1) solid $g-color6;
  caret-color: $g-color11;
  line-height: rem(18);

  &:disabled {
    opacity: 0.6;
    background-color: inherit;
  }

  &:focus {
    outline: none;

    border-color: $g-color11;
  }

  &.has-error {
    box-shadow: none;

    border: rem(1) solid $g-color20;

    &:focus {
      border-color: $g-color11;
    }
  }
}

@mixin slide-toggle($size, $thumb-color, $background-color, $text-color: null) {
  position: relative;

  .mat-slide-toggle-bar {
    height: rem(24);
    border-radius: rem(50);

    @if $size == small {
      width: rem(44);
    } @else if $size == medium {
      width: rem(80);
    } @else if $size == large {
      width: rem(120);
    }
  }

  .mat-slide-toggle-thumb-container {
    top: rem(2);
    @include ptl-direction(position, left, rem(2));
  }

  &.mat-checked {
    .mat-slide-toggle-thumb-container {
      $translation: 20;

      @if $size == medium {
        $translation: 56;
      } @else if $size == large {
        $translation: 96;
      }

      :host-context([dir='rtl']) &,
      ::ng-deep .cdk-overlay-container [dir='rtl'] .cdk-overlay-pane & {
        transform: translate3d(rem(-$translation), 0, 0);
      }

      :host-context([dir='ltr']) &,
      ::ng-deep .cdk-overlay-container [dir='ltr'] .cdk-overlay-pane & {
        transform: translate3d(rem($translation), 0, 0);
      }
    }
  }

  @include slide-toggle-color($thumb-color, $background-color, $text-color);
}

@mixin slide-toggle-color($thumb-color, $background-color, $text-color: null) {
  @if ($text-color) {
    .mat-slide-toggle-bar {
      background-color: $thumb-color;
      border: rem(1) solid $text-color;
    }

    .mat-slide-toggle-content {
      color: $text-color;
    }

    .mat-slide-toggle-thumb {
      background-color: $text-color;
    }
  }

  &.mat-checked {
    .mat-slide-toggle-bar {
      background-color: $background-color;

      @if ($text-color) {
        border: rem(1) solid $background-color;
      }
    }

    .mat-slide-toggle-thumb {
      background-color: $thumb-color;
    }

    .mat-ripple-element {
      background-color: $background-color;
    }

    @if ($text-color) {
      .mat-slide-toggle-content {
        color: $thumb-color;
      }
    }
  }
}

@mixin card-left-top-triangle() {
  content: '';
  border-style: solid;
  border-width: rem(15);
  @include ptl-direction(radius, bottom-right, rem(4));
  width: 0;
  height: 0;
  position: absolute;
  @include ptl-direction(position, left, rem(0));
  top: 0;
  border-color: $g-color9 $color--picto-dark-blue $color--picto-dark-blue $g-color9;
  box-shadow:
    rem(-5) 0 0 $g-color9,
    0 rem(-5) 0 $g-color9;
}

@mixin tags-item($background-color) {
  background-color: $background-color;
  border-radius: rem(4);
  padding: rem(3) rem(10);
  @include ptl-direction(margin, right, rem(10));
  margin-bottom: rem(5);

  .bold {
    font-weight: 500;
  }
}

@mixin search-input() {
  &.mat-focused {
    ::ng-deep {
      .mat-input-element {
        caret-color: $color--picto-dark-blue;
      }

      .mat-form-field-flex {
        color: $color--picto-dark-blue;
      }

      .mat-form-field-outline {
        color: $color--picto-dark-blue !important;
      }
    }
  }

  &.mat-form-field-can-float {
    &.mat-form-field-should-float {
      ::ng-deep {
        .mat-form-field-label {
          transform: translateY(rem(-17)) scale(0.75);
          width: 133.33333%;
        }
      }
    }
  }

  ::ng-deep {
    .mat-form-field-label {
      margin-top: 0;
    }

    .mat-form-field-infix {
      padding: rem(7) rem(5);
      border-top-width: rem(4);
      margin: 0;
    }

    .mat-form-field-wrapper {
      .mat-input-element {
        font-size: rem(12);
        color: initial;
        line-height: rem(24);
      }
    }

    .mat-input-element {
      caret-color: $color--black-border;
      color: $color--black;
    }

    .mat-form-field-flex {
      color: $color--black-border;
    }

    .mat-form-field-outline {
      color: $color--black-border !important;

      div {
        border-width: rem(1) !important;
      }
    }

    .mat-form-field-prefix,
    .mat-form-field-suffix {
      top: 0 !important;
    }
  }
}

@mixin tag-input() {
  ::ng-deep {
    vertical-align: middle;
    width: 100%;
    max-width: rem(130);
    @include ptl-direction(margin, left, rem(10));

    .mat-form-field-infix {
      padding: rem(4) 0;
      border-top: rem(4) solid transparent;
    }

    .mat-form-field-outline-end {
      border-radius: 0 rem(4) rem(4) 0;
    }

    .mat-form-field-outline-thick {
      .mat-form-field-outline-start,
      .mat-form-field-outline-end,
      .mat-form-field-outline-gap {
        border-width: rem(1);
      }
    }

    &.mat-form-field-invalid {
      .mat-input-element {
        caret-color: $color--black-border;
        color: $color--black;
      }
    }

    .mat-form-field-wrapper {
      padding-bottom: 0;

      .mat-input-element {
        font-size: rem(12);
        color: initial;
      }
    }

    .mat-form-field-flex,
    .mat-form-field-outline {
      width: 100%;
      max-width: rem(130);
      color: $color--black-border;
    }

    .mat-form-field-outline {
      color: $color--black-border;
      top: 0;
    }

    .mat-form-field-subscript-wrapper {
      top: rem(16);
      @include ptl-direction(text-align, right);
      padding: 0;
    }
  }
  &.mat-focused {
    ::ng-deep {
      &.mat-form-field-invalid {
        .mat-input-element {
          caret-color: $color--picto-dark-blue;
        }
      }

      .mat-form-field-flex,
      .mat-form-field-outline {
        color: $color--picto-dark-blue;
      }

      .mat-form-field-outline {
        color: $color--picto-dark-blue;
      }
    }
  }
}

@mixin question-mat-slider() {
  ::ng-deep {
    .mat-slider.mat-slider-horizontal .mat-slider-wrapper {
      top: rem(5);
    }

    .mat-slider.mat-slider-horizontal .mat-slider-track-wrapper {
      height: rem(16);
      border-radius: rem(20);
      top: rem(7);
      width: auto;
      @include ptl-direction(position, left, rem(-15));
      @include ptl-direction(position, right, rem(-15));
    }

    .mat-slider.mat-slider-horizontal .mat-slider-track-background,
    .mat-slider.mat-slider-horizontal .mat-slider-track-fill {
      height: 100%;
      background-color: $color--sandy-brown;
    }

    .mat-slider.mat-slider-horizontal .mat-slider-thumb-label {
      background-color: $color--sandy-brown;
      width: rem(30);
      height: rem(30);
    }

    .mat-primary {
      .mat-slider-thumb {
        height: rem(30);
        width: rem(30);
        background-color: $color--white;
        border: rem(2) solid $color--cerulean !important;
        bottom: rem(-29);
        @include ptl-direction(position, right, rem(-15));
        transform: scale(1) !important;

        &:before {
          content: '';
          border-style: solid;
          width: 0;
          height: 0;
          @include ptl-direction(position, left, rem(4));
          top: rem(7);
          line-height: rem(0);
          border-color: transparent $color--cerulean transparent transparent;
          border-width: rem(6) rem(7) rem(6) 0;
          position: absolute;
        }

        &:after {
          content: '';
          border-style: solid;
          width: 0;
          height: 0;
          @include ptl-direction(position, right, rem(4));
          top: rem(7);
          line-height: rem(0);
          border-color: transparent transparent transparent $color--cerulean;
          border-width: rem(6) 0 rem(6) rem(7);
          position: absolute;
        }
      }
    }

    .mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
      background-color: $color--white;
    }
  }
}

@mixin question-mat-slider-new() {
  ::ng-deep {
    .mat-slider.mat-slider-horizontal .mat-slider-wrapper {
      top: rem(5);
    }

    .mat-slider.mat-slider-horizontal .mat-slider-track-wrapper {
      height: rem(12);
      border-radius: rem(4);
      top: rem(7);
      width: auto;
      left: rem(-15);
      right: rem(-15);
    }

    .mat-slider.mat-slider-horizontal .mat-slider-track-background {
      height: 100%;
      background-color: $g-color12;
    }

    .mat-slider.mat-slider-horizontal .mat-slider-track-fill {
      height: 100%;
      background-color: $g-color11;
    }

    .mat-primary {
      .mat-slider-thumb {
        height: rem(32);
        width: rem(32);
        background-color: $g-color11;
        border: rem(2) solid $g-color11 !important;
        bottom: rem(-29);
        right: rem(-15);
        transform: scale(1) !important;

        &:before {
          font-family: 'Material Icons';
          content: 'keyboard_arrow_left';
          position: absolute;
          left: rem(-3);
          top: rem(4);
          font-size: rem(20);
          line-height: rem(20);
          padding: 0;
          margin: 0;
          color: $g-color10;
        }

        &:after {
          font-family: 'Material Icons';
          content: 'keyboard_arrow_right';
          position: absolute;
          right: rem(-3);
          top: rem(4);
          font-size: rem(20);
          line-height: rem(20);
          padding: 0;
          margin: 0;
          color: $g-color10;
        }
      }
    }
  }
}

@mixin cards-top-action-bar {
  .cards-action-bar-learner-button-item {
    &.mat-menu-item {
      color: $color--black;
    }
  }

  .card-view__top-bar {
    padding: rem(5) rem(20);
    display: flex;
    align-items: center;
    @include ptl-direction(radius, top-left, rem(0), true);
    @include ptl-direction(radius, top-right, rem(0), true);

    &.admin-view {
      background-color: rgba($color--royal-blue, 15%);
    }

    @include breakpoint(mobileOnly) {
      padding: rem(5) rem(10);
    }
  }

  .cards-action-bar-learner {
    display: flex;
    align-items: center;
    @include ptl-direction(margin, left, auto);

    .cards-action-bar-learner-button-item {
      color: $color--royal-blue;

      &.is_active {
        color: $color--picto-dark-blue;
      }

      i {
        font-size: rem(24);
      }

      .like-info__vote-btn {
        color: $color--royal-blue;
        @include ptl-direction(margin, right, rem(10));

        i {
          @include ptl-direction(margin, right, rem(0));
        }

        .like-icon {
          @include ptl-direction(margin, right, rem(0));
          @include ptl-direction(margin, left, rem(0));
        }
      }
    }
  }

  .cards__actions-bar {
    display: flex;
    align-items: center;
    white-space: nowrap;
    min-height: rem(40);
    @include ptl-direction(margin, left, auto);

    .cards__toolbar-buttons {
      button {
        vertical-align: middle;
        color: $color--black;
        white-space: unset;

        i {
          font-size: rem(24);
        }
      }

      .bordered-button {
        border: rem(1) solid $color--royal-blue;
        border-radius: rem(4);
        min-width: rem(40);
        height: rem(40);
        @include ptl-direction(margin, right, rem(5));
        color: $color--royal-blue;

        &.icon-with-text {
          @include breakpoint(mobileOnly) {
            padding: 0;

            .text {
              display: none;
            }
          }
        }
      }

      .more-actions {
        font-size: rem(24);
        color: $color--royal-blue;
      }
    }
  }

  .cards__toolbar-button-item {
    color: $color--black;
    white-space: unset;

    &.is_active {
      color: $color--royal-blue;
    }

    &.mat-menu-item {
      .mat-icon {
        color: $color--black;
        @include ptl-direction(margin, right, rem(6));
      }
    }

    .mat-icon {
      color: $color--black;
      @include ptl-direction(margin, right, rem(6));
    }

    &.mat-menu-item-submenu-trigger::after {
      color: $color--black;
    }

    &.copy-button {
      i {
        font-size: rem(22);
      }

      .mat-spinner {
        display: inline-block;
        vertical-align: middle;
        @include ptl-direction(margin, right, rem(11));
      }

      ::ng-deep {
        .mat-progress-spinner,
        .mat-spinner {
          circle {
            color: $color--black;
          }
        }
      }
    }

    &.share-button {
      i {
        font-size: rem(22);
      }
    }

    i {
      font-size: rem(24);
      vertical-align: middle;
      color: $color--black;
      @include ptl-direction(margin, right, rem(6));
      width: rem(24);
      height: rem(24);
      display: inline-block;
    }
  }
}

@mixin section-table() {
  .table-section-list {
    display: table;
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;

    .row-cell {
      display: table-cell;
      vertical-align: middle;
      padding: 0 rem(16);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .remove-cell {
      width: rem(35);
    }

    .remove-cell-inner {
      display: flex;
      justify-content: flex-end;
    }

    .name-cell {
      img {
        object-fit: cover;
        border-radius: 50%;
        width: rem(24);
        height: rem(24);
        vertical-align: middle;
        @include ptl-direction(margin, right, rem(5));
      }
    }

    .image-cell {
      width: rem(75);
      text-align: center;
      position: relative;

      @include breakpoint(mobileOnly) {
        display: none;
      }
    }

    .remove-btn {
      display: inline-flex;
      vertical-align: middle;
      width: rem(40);
      height: rem(30);
      cursor: pointer;
      @include ptl-direction(margin, left, rem(30));

      .mat-icon {
        line-height: rem(30);
        text-align: center;
      }
    }

    .manage-btn {
      color: $color--picto-dark-blue;
      font-size: rem(12);
      text-decoration: none;
      display: inline-block;
      vertical-align: middle;

      &.is_disabled {
        opacity: 0.5;
        pointer-events: none;
      }

      &:hover {
        color: $color--mine-shaft;
      }
    }
  }

  .table-section-list-header {
    color: $color--black;
    font-weight: 500;

    .remove-cell {
      overflow: unset;
    }
  }

  .table-section-list-header,
  .table-section-list-row {
    display: table-row;
    width: 100%;
    @include ptl-direction(text-align, left);
    border-bottom: rem(1) solid $color--alto;
    vertical-align: middle;
    height: rem(48);
    box-sizing: border-box;
    table-layout: fixed;

    &:last-child {
      border-bottom: none;
    }
  }
}

@mixin callout-section() {
  display: flex;
  padding: rem(32);
  align-items: center;

  @media (max-width: rem(1224)) {
    flex-direction: column;
  }

  ::ng-deep {
    .swiper-slide {
      padding: rem(10) rem(10);
      box-sizing: border-box;
      margin: rem(-2) 0;
    }

    .add-element-btn-container {
      height: 100%;
      display: flex;
      flex-direction: column;

      .add-element-btn {
        height: 100%;
        flex-grow: 1;
        box-shadow: 0 rem(2) rem(4) 0 rgba(0, 0, 0, 0.4);
      }
    }
  }

  .slider-arrow {
    display: block;
    text-align: center;
    position: absolute;
    z-index: 20;
    top: 50%;
    transform: translateY(-50%);
    border: none;
    padding: 0;
    background: transparent;
    cursor: pointer;
    color: $color--picto-dark-blue;
    transition: color 0.3s ease;

    .svg {
      width: rem(35);
      height: rem(35);
    }

    &.swiper-button-disabled {
      display: none;
    }

    &:hover {
      color: $color--cerulean;
    }

    .mat-icon {
      width: rem(40);
      height: rem(40);
      font-size: rem(40);
      line-height: rem(40);
    }

    &.arrow-prev {
      @include ptl-direction(position, left, rem(-23));
    }

    &.arrow-next {
      @include ptl-direction(position, right, rem(-23));
    }
  }

  .left-side {
    width: 100%;
    align-items: center;
    flex: 1;
    @include ptl-direction(padding, right, rem(35));
    word-break: break-word;

    @media (max-width: rem(1224)) {
      @include ptl-direction(padding, right, rem(0));
    }
  }

  .right-side {
    flex: 0 0 rem(520);
    max-width: rem(520);

    @media (max-width: rem(1224)) {
      max-width: 100%;
      width: 100%;
      flex: 1;
    }

    .swiper {
      padding: rem(20) 0;
      width: 100%;
    }
  }

  .playlist-section {
    display: flex;
    position: relative;
    justify-content: center;
    margin: rem(-20) rem(-13);

    @include breakpoint(mobilePortraitonly) {
      margin: 0 rem(-13);
    }

    .card-item-mock:after {
      background-color: $g-color3;
      border-radius: rem(8);
      display: block;
      height: 100%;
      opacity: 0.1;
      content: '';
    }

    .card-item {
      display: inline-block;
      vertical-align: top;
      box-sizing: border-box;
      width: 100%;
      height: 100%;

      &.full-width {
        width: 80%;
      }

      &.card-item-small {
        ::ng-deep {
          .link-card,
          .card,
          .add-element-btn-container {
            min-height: rem(272);
            height: 100%;

            @include breakpoint(mobilePortraitonly) {
              height: rem(212);
            }
          }

          .card {
            .card-info-box {
              &.has-bottom-border {
                &:after {
                  display: none;
                }
              }
            }
          }

          .card-box {
            @include breakpoint(mobilePortraitonly) {
              .card-box-image-box {
                height: rem(110);
              }

              .card-box-info-box {
                min-height: rem(22);

                .card-box-title {
                  font-size: rem(12);
                }
              }
            }

            .card-box-footer-box {
              padding: rem(8) rem(16) 0;
            }
          }
        }
      }

      &:not(.card-item-small) {
        width: 100%;
      }
    }
  }
}

@mixin thin-editor() {
  div,
  p,
  ul,
  ol,
  li {
    margin: 0 0 rem(5);
    padding: 0;
    font-size: rem(16);
    font-family: 'charter-regular', sans-serif;
  }

  ul,
  ol {
    @include ptl-direction(padding, left, rem(30));
  }

  li {
    margin-bottom: 0;
  }

  a {
    font-family: inherit;
    text-decoration: underline;
    color: inherit;

    &:visited {
      color: inherit;
    }
  }

  blockquote {
    font-size: rem(15);
    font-family: 'charter-black', sans-serif;
  }

  u {
    text-decoration: inherit;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0;
    line-height: 1.42857;
    font-family: 'charter-bold', sans-serif;
  }

  font[size='1'],
  [style*='font-size: xx-small'],
  [style*='font-size: x-small'] {
    font-size: rem(10) !important;
  }

  font[size='2'],
  [style*='font-size: small'],
  [style*='font-size: smaller'] {
    font-size: rem(22) !important;
  }

  font[size='3'],
  [style*='font-size: medium'] {
    font-size: rem(15) !important;
  }

  font[size='4'],
  [style*='font-size: large'] {
    font-size: rem(18) !important;
  }

  font[size='5'],
  font[size='6'],
  font[size='7'],
  [style*='font-size: x-large'],
  [style*='font-size: larger'],
  [style*='font-size: xx-large'] {
    font-size: rem(25) !important;
  }

  [style*='font-weight: bold'] {
    font-family: 'charter-bold', sans-serif !important;
  }

  h1 {
    font-size: rem(25);
    line-height: rem(30);

    * {
      font-size: inherit !important;
      font-family: 'charter-bold', sans-serif;
    }
  }

  h2 {
    font-size: rem(20);
    line-height: rem(25);

    * {
      font-size: inherit !important;
      font-family: 'charter-bold', sans-serif;
    }
  }

  h3 {
    font-size: rem(18);
    line-height: rem(20);

    * {
      font-size: inherit !important;
      font-family: 'charter-bold', sans-serif;
    }
  }

  h4 {
    font-size: rem(16);
    line-height: rem(18);

    * {
      font-size: inherit !important;
      font-family: 'charter-bold', sans-serif;
    }
  }

  h5 {
    font-size: rem(14);
    line-height: rem(16);

    * {
      font-size: inherit !important;
      font-family: 'charter-bold', sans-serif;
    }
  }

  h6 {
    font-size: rem(12);
    line-height: rem(14);

    * {
      font-size: inherit !important;
      font-family: 'charter-bold', sans-serif;
    }
  }
}

@mixin note-item {
  .note-item {
    margin-bottom: rem(16);

    .note-item-inner {
      background-color: $g-color9;
      padding: rem(12);
      position: relative;
      border-radius: rem(6) rem(6) 0 rem(6);

      &:after {
        position: absolute;
        top: 100%;
        @include ptl-direction(position, right, rem(0));
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 rem(24) rem(24) 0;
        border-color: transparent $g-color9 transparent transparent;
      }
    }

    .note-added-by {
      @include ptl-direction(padding, right, rem(32));
      margin-top: rem(4);
      @include ptl-direction(text-align, right);
      font-style: italic;
    }
  }
}

@mixin ptl-direction($property, $direction, $value: null, $withoutCheckingDirection: false) {
  // set property without checking direction
  @if $withoutCheckingDirection {
    @if $property == margin {
      @if $direction == left {
        margin-left: $value;
      } @else if $direction == right {
        margin-right: $value;
      }
    }

    @if $property == padding {
      @if $direction == left {
        padding-left: $value;
      } @else if $direction == right {
        padding-right: $value;
      }
    }

    @if $property == position {
      @if $direction == left {
        left: $value;
      } @else if $direction == right {
        right: $value;
      }
    }

    @if $property == float {
      @if $direction == left {
        float: left $value;
      } @else if $direction == right {
        float: right $value;
      }
    }

    @if $property == text-align {
      @if $direction == left {
        text-align: left $value;
      } @else if $direction == right {
        text-align: right $value;
      }
    }

    @if $property == justify-content {
      @if $direction == left {
        justify-content: left $value;
      } @else if $direction == right {
        justify-content: right $value;
      }
    }

    @if $property == border {
      @if $direction == left {
        border-left: $value;
      } @else if $direction == right {
        border-right: $value;
      } @else if $direction == left-width {
        border-left-width: $value;
      } @else if $direction == right-width {
        border-right-width: $value;
      }
    }

    @if $property == transform-origin-top {
      @if $direction == left {
        transform-origin: top left;
      } @else if $direction == right {
        transform-origin: top right;
      }
    }

    @if $property == transform-origin-top {
      @if $direction == left {
        transform-origin: top left;
      } @else if $direction == right {
        transform-origin: top right;
      }
    }

    @if $property == radius {
      @if $direction == top-left {
        border-top-left-radius: $value;
      } @else if $direction == bottom-left {
        border-bottom-left-radius: $value;
      } @else if $direction == top-right {
        border-top-right-radius: $value;
      } @else if $direction == bottom-right {
        border-bottom-right-radius: $value;
      }
    }
  } @else {
    @if $property == margin {
      :host-context([dir='rtl']) &,
      ::ng-deep .cdk-overlay-container [dir='rtl'] .cdk-overlay-pane & {
        @if $direction == left {
          margin-right: $value;
        } @else if $direction == right {
          margin-left: $value;
        }
      }

      :host-context([dir='ltr']) &,
      ::ng-deep .cdk-overlay-container [dir='ltr'] .cdk-overlay-pane & {
        @if $direction == left {
          margin-left: $value;
        } @else if $direction == right {
          margin-right: $value;
        }
      }
    }

    @if $property == padding {
      :host-context([dir='rtl']) &,
      ::ng-deep .cdk-overlay-container [dir='rtl'] .cdk-overlay-pane & {
        @if $direction == left {
          padding-right: $value;
        } @else if $direction == right {
          padding-left: $value;
        }
      }

      :host-context([dir='ltr']) &,
      ::ng-deep .cdk-overlay-container [dir='ltr'] .cdk-overlay-pane & {
        @if $direction == left {
          padding-left: $value;
        } @else if $direction == right {
          padding-right: $value;
        }
      }
    }

    @if $property == position {
      :host-context([dir='rtl']) &,
      ::ng-deep .cdk-overlay-container [dir='rtl'] .cdk-overlay-pane & {
        @if $direction == left {
          right: $value;
        } @else if $direction == right {
          left: $value;
        }
      }

      :host-context([dir='ltr']) &,
      ::ng-deep .cdk-overlay-container [dir='ltr'] .cdk-overlay-pane & {
        @if $direction == left {
          left: $value;
        } @else if $direction == right {
          right: $value;
        }
      }
    }

    @if $property == float {
      :host-context([dir='rtl']) &,
      ::ng-deep .cdk-overlay-container [dir='rtl'] .cdk-overlay-pane & {
        @if $direction == left {
          float: right $value;
        } @else if $direction == right {
          float: left $value;
        }
      }

      :host-context([dir='ltr']) &,
      ::ng-deep .cdk-overlay-container [dir='ltr'] .cdk-overlay-pane & {
        @if $direction == left {
          float: left $value;
        } @else if $direction == right {
          float: right $value;
        }
      }
    }

    @if $property == text-align {
      :host-context([dir='rtl']) &,
      ::ng-deep .cdk-overlay-container [dir='rtl'] .cdk-overlay-pane & {
        @if $direction == left {
          text-align: right $value;
        } @else if $direction == right {
          text-align: left $value;
        }
      }

      :host-context([dir='ltr']) &,
      ::ng-deep .cdk-overlay-container [dir='ltr'] .cdk-overlay-pane & {
        @if $direction == left {
          text-align: left $value;
        } @else if $direction == right {
          text-align: right $value;
        }
      }
    }

    @if $property == justify-content {
      :host-context([dir='rtl']) &,
      ::ng-deep .cdk-overlay-container [dir='rtl'] .cdk-overlay-pane & {
        @if $direction == left {
          justify-content: right $value;
        } @else if $direction == right {
          justify-content: left $value;
        }
      }

      :host-context([dir='ltr']) &,
      ::ng-deep .cdk-overlay-container [dir='ltr'] .cdk-overlay-pane & {
        @if $direction == left {
          justify-content: left $value;
        } @else if $direction == right {
          justify-content: right $value;
        }
      }
    }

    @if $property == border {
      :host-context([dir='rtl']) &,
      ::ng-deep .cdk-overlay-container [dir='rtl'] .cdk-overlay-pane & {
        @if $direction == left {
          border-right: $value;
        } @else if $direction == right {
          border-left: $value;
        } @else if $direction == left-width {
          border-right-width: $value;
        } @else if $direction == right-width {
          border-left-width: $value;
        }
      }

      :host-context([dir='ltr']) &,
      ::ng-deep .cdk-overlay-container [dir='ltr'] .cdk-overlay-pane & {
        @if $direction == left {
          border-left: $value;
        } @else if $direction == right {
          border-right: $value;
        } @else if $direction == left-width {
          border-left-width: $value;
        } @else if $direction == right-width {
          border-right-width: $value;
        }
      }
    }

    @if $property == transform-origin-top {
      :host-context([dir='rtl']) &,
      ::ng-deep .cdk-overlay-container [dir='rtl'] .cdk-overlay-pane & {
        @if $direction == left {
          transform-origin: top right;
        } @else if $direction == right {
          transform-origin: top left;
        }
      }

      :host-context([dir='ltr']) &,
      ::ng-deep .cdk-overlay-container [dir='ltr'] .cdk-overlay-pane & {
        @if $direction == left {
          transform-origin: top left;
        } @else if $direction == right {
          transform-origin: top right;
        }
      }
    }

    @if $property == radius {
      :host-context([dir='rtl']) &,
      ::ng-deep .cdk-overlay-container [dir='rtl'] .cdk-overlay-pane & {
        @if $direction == top-left {
          border-top-right-radius: $value;
        } @else if $direction == bottom-left {
          border-bottom-right-radius: $value;
        } @else if $direction == top-right {
          border-top-left-radius: $value;
        } @else if $direction == bottom-right {
          border-bottom-left-radius: $value;
        }
      }

      :host-context([dir='ltr']) &,
      ::ng-deep .cdk-overlay-container [dir='ltr'] .cdk-overlay-pane & {
        @if $direction == top-left {
          border-top-left-radius: $value;
        } @else if $direction == bottom-left {
          border-bottom-left-radius: $value;
        } @else if $direction == top-right {
          border-top-right-radius: $value;
        } @else if $direction == bottom-right {
          border-bottom-right-radius: $value;
        }
      }
    }
  }
}

@mixin tab-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  .tab-bar-container-inner {
    display: inline-flex;
    border-radius: rem(4);
    border: rem(1) solid $g-color23;
    overflow: hidden;

    .option-button {
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      padding: rem(6) rem(12);
      background-color: white;
      border: none;
      fill: $g-color23;
      cursor: pointer;
      color: $g-color23;

      &:not(:first-child) {
        border-left: rem(1) solid $g-color23;
      }

      &.selected {
        background-color: $g-color23;
        fill: $color--white;
        color: $color--white;
      }
    }
  }
}

@mixin custom-scroll-thumb {
  overflow: auto;
  scrollbar-color: $color--mercury $color--silver-chalice;
  scrollbar-width: thin;
  z-index: 10;

  /* Track */
  &::-webkit-scrollbar-track {
    background: $color--mercury;
    -webkit-box-shadow: inset 0 0 rem(6) $color--mercury;
    border-radius: rem(10);
  }

  /* Handle scrollbar thumb */
  &::-webkit-scrollbar-thumb {
    background: $color--silver-chalice;
    border-radius: rem(10);
    -webkit-box-shadow: inset 0 0 rem(6) $color--silver-chalice;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: $color--star-dust;
    border-radius: rem(10);
    -webkit-box-shadow: inset 0 0 rem(6) $color--star-dust;
  }
}

@mixin dynamic-content-caption-box {
  display: flex;
  justify-content: center;
  width: 100%;

  .caption-text {
    font-family: 'DM Sans', sans-serif;
    font-size: rem(13);
    line-height: rem(20);
    margin: rem(2);
    font-style: italic;
    color: $color--monday-gray;
    font-weight: 350;
  }
}

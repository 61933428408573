/*
 * Copyright (C) 2023 - Potentially Ltd
 *
 * Please see distribution for license.
 */

@import 'rem';

/** Defines the responsive breakpoints to use across the different screens */
@mixin breakpoint($point) {
  @if $point == desktopLarge {
    @media (max-width: rem(1800)) {
      @content;
    }
  } @else if $point == desktop {
    @media (min-width: rem(1024)) {
      @content;
    }
  } @else if $point == aboveMobile {
    @media (min-width: rem(901)) {
      @content;
    }
  } @else if $point == laptop {
    @media (max-width: rem(1440)) {
      @content;
    }
  } @else if $point == mobileOnly {
    @media (max-width: rem(900)) {
      @content;
    }
  } @else if $point == tabletPortraitOnly {
    @media (max-width: rem(767)) {
      @content;
    }
  } @else if $point == mobilePortraitonly {
    @media (max-width: rem(480)) {
      @content;
    }
  } @else if $point == mobileAndTablet {
    @media (max-width: rem(1024)) {
      @content;
    }
  }
  @if $point == desktopHeight {
    @media (min-height: rem(768)) {
      @content;
    }
  }
}

@import 'rem';

@media print {
  @page {
    size: A4 !important;
    margin: 7mm 10mm;
  }

  body {
    width: 210mm;

    &[dir='rtl'],
    [dir='rtl'] {
      mat-sidenav-content {
        @include ptl-direction(margin, right, rem(0) !important, true);
      }

      .report-wrapper__inner {
        .resource-cards-container {
          @include ptl-direction(padding, left, rem(56), true);
          @include ptl-direction(padding, right, rem(46), true);
        }
      }
    }

    &[dir='ltr'],
    [dir='ltr'] {
      mat-sidenav-content {
        @include ptl-direction(margin, left, rem(0) !important, true);
      }

      .report-wrapper__inner {
        .resource-cards-container {
          @include ptl-direction(padding, right, rem(56), true);
          @include ptl-direction(padding, left, rem(46), true);
        }
      }
    }
  }

  header,
  mat-sidenav,
  ptl-breadcrumbs {
    display: none !important;
  }

  mat-sidenav-content {
    margin-top: 0 !important;
  }

  .resource-view-page {
    display: initial !important;
  }

  ptl-diagnostics-view-report {
    display: block;
    box-sizing: border-box;
    padding: rem(0) rem(10);
  }

  ptl-diagnostics-report-standard-section,
  ptl-diagnostics-report-conditional-section-new,
  ptl-diagnostics-report-resource-section,
  ptl-diagnostics-report-conditional-section {
    display: block;
  }

  ptl-diagnostics-report-standard-section,
  ptl-diagnostics-report-conditional-section-new,
  ptl-diagnostics-report-conditional-section {
    &:not(.is_first) {
      page-break-before: always;
    }
  }

  * {
    -webkit-print-color-adjust: exact !important; /* Chrome, Safari, Edge */
    print-color-adjust: exact !important; /*Firefox*/
  }

  #chartTooltip,
  #cdk-describedby-message-container,
  .chartjs-size-monitor,
  .chartjs-size-monitor-expand,
  .chartjs-size-monitor-shrink,
  .cards__toolbar-buttons,
  .card-layout-top-container,
  .top-back-button,
  .resource-card,
  .report-view__close-btn,
  .is_print-hidden,
  .pagination-bottom-action,
  .pagination-buttons-container,
  ptl-learner-section-buttons,
  ptl-playlist-action-bar,
  ptl-playlist--palette,
  ptl-group-card-palette,
  ptl-next-previous-buttons {
    display: none !important;
  }

  .pagination-page {
    .page-label {
      display: none;
    }
  }

  ptl-skeleton-view,
  ptl-learner-main-view-content,
  ptl-resource-preview,
  ptl-resource-html-preview,
  ptl-resource-form-preview,
  ptl-resource-image-preview,
  ptl-resource-video-preview,
  ptl-resource-document-preview,
  ptl-external-preview,
  ptl-chart-preview,
  ptl-pi-trait-chart,
  ptl-pi-team-role-chart,
  ptl-pi-big-six-chart,
  ptl-pi-jungian-chart,
  ptl-pi-spectrum-chart,
  ptl-collector-preview,
  ptl-playlist-card-snake-view-container,
  ptl-playlist-card-snake-view-type,
  ptl-playlist-card,
  .report-wrapper__inner,
  .resource-card-print,
  .content__container,
  .preview,
  .preview__area,
  .snake-view-cards-container,
  .playlist-card {
    display: block !important;
  }

  .resource-view-page {
    &:not(.current-page) {
      position: relative;
      page-break-inside: avoid;
      display: block !important;
    }
  }

  ptl-resource-form-preview,
  ptl-resource-image-preview,
  ptl-pi-trait-chart,
  ptl-pi-team-role-chart,
  ptl-pi-big-six-chart,
  ptl-pi-jungian-chart,
  ptl-pi-spectrum-chart,
  .print-content {
    page-break-inside: avoid;
  }

  ptl-playlist-card,
  .form-preview-type,
  .external-preview__container,
  .collector-preview__section {
    position: relative;
    page-break-inside: avoid;
  }

  .video-preview-element {
    video {
      position: relative;
      page-break-inside: avoid;
    }
  }

  .preview-element-document-container,
  .preview-element {
    iframe,
    img {
      position: relative;
      page-break-inside: avoid;
    }
  }

  .section {
    margin-bottom: rem(20);
    padding-top: rem(20) !important;
    padding-bottom: rem(20) !important;
  }

  .playlist-card__container {
    margin-bottom: rem(20);
  }

  .form-preview__top {
    .form-preview__top-right {
      mat-checkbox {
        display: none;
      }

      .printable {
        display: initial;
        box-sizing: border-box;
        width: rem(10);
        height: rem(10);
        outline: rem(2) solid $color--gray;

        &.checked {
          border: rem(5) solid $color--black;
        }
      }
    }
  }

  .card-view {
    margin-bottom: rem(20);
  }

  .report-wrapper__inner {
    button,
    .sticky-panel {
      display: none !important;
    }

    ptl-chart-preview {
      page-break-inside: avoid;

      .user-image,
      .user-no-image {
        transform: translate(-50%, -50%) !important;
      }

      .chart-preview-section__keys {
        display: flex;
      }

      .circle {
        display: none !important;
      }
    }

    .print-content {
      display: block;
      font-size: rem(21);
      line-height: rem(32);

      a {
        color: inherit;
      }
    }

    .conditional-report-section__intro {
      display: block !important;

      .conditional-report-section__intro__chart {
        page-break-inside: avoid;

        svg {
          width: rem(100);
          height: rem(100);
        }
      }
    }

    .resource-cards-container {
      padding-top: rem(10);
      padding-bottom: rem(10);

      .swiper {
        padding: 0;
        margin: 0;
      }

      .slider-arrow {
        display: none !important;
      }

      .swiper-wrapper {
        display: block !important;
        transform: none !important;
      }

      .swiper-slide {
        width: 100% !important;
        margin: rem(5) 0;
      }
    }

    canvas {
      width: rem(649) !important;
      height: auto !important;
      margin: 0 auto;
      display: block !important;
      page-break-inside: avoid;
    }

    .chart-preview-version-select {
      .apply-button {
        display: none;
      }
    }
  }

  .cdk-global-scrollblock {
    position: static;
    overflow: visible;
  }

  .dialog-print {
    ptl-index-main {
      display: none;
    }

    .cdk-overlay-container {
      position: static;
      height: auto;

      .cdk-overlay-backdrop {
        display: none;
      }
    }

    .cdk-global-overlay-wrapper {
      position: static;
      display: block;
      height: auto;
    }
  }

  .assessment-report-dialog {
    max-height: unset !important;
    height: unset;
    margin-top: 0 !important;

    .mat-dialog-container {
      overflow: visible;
    }
  }
}

@font-face {
  font-family: 'DM Sans';
  src:
    url('dmsans-regular.woff2') format('woff2'),
    url('dmsans-regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'DM Sans';
  src:
    url('dmsans-bold.woff2') format('woff2'),
    url('dmsans-bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'DM Sans';
  src:
    url('dmsans-medium.woff2') format('woff2'),
    url('dmsans-medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'DM Sans';
  src:
    url('dmsans-italic.woff2') format('woff2'),
    url('dmsans-italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

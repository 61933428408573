/*
 * Copyright (C) 2023 - Potentially Ltd
 *
 * Please see distribution for license.
 */
@import 'colors';
@import 'variables';
@import 'rem';
@import 'mixins';

body {
  &[dir='rtl'],
  [dir='rtl'] {
    .mat-menu-item {
      .mat-icon {
        @include ptl-direction(margin, left, rem(16), true);
      }
    }

    .dialog-header {
      .dialog-header__close-icon {
        @include ptl-direction(margin, right, auto, true);
      }
    }

    .mat-radio-label-content {
      @include ptl-direction(padding, right, rem(8), true);
    }
  }

  &[dir='ltr'],
  [dir='ltr'] {
    .mat-menu-item {
      .mat-icon {
        @include ptl-direction(margin, right, rem(16), true);
      }
    }

    .dialog-header {
      .dialog-header__close-icon {
        @include ptl-direction(margin, left, auto, true);
      }
    }

    .mat-radio-label-content {
      @include ptl-direction(padding, left, rem(8), true);
    }
  }

  .mat-ink-bar {
    background-color: $g-color11 !important;
  }

  .spinner-white circle {
    stroke: $color--white;
  }

  .spinner-primary circle {
    stroke: $g-color11;
  }

  .mat-progress-bar.mat-green {
    .mat-progress-bar-buffer {
      background-color: $color--gossip;
    }

    .mat-progress-bar-element::after {
      background-color: $color--dark-emerald;
    }
  }

  .mat-stroked-button,
  .mat-raised-button,
  .mat-flat-button {
    &.mat-blue {
      color: $color--picto-dark-blue;
      border: rem(1) solid $color--picto-dark-blue;

      &:hover {
        box-shadow: 0 rem(2) rem(6) rgba(0, 0, 0, 0.3);
        transition: box-shadow 200ms cubic-bezier(0.35, 0, 0.25, 1);
      }
    }

    &.mat-green-transparent {
      color: $color--dark-emerald;
      background-color: rgba($color: $color--white, $alpha: 0);
      border: rem(1) solid $color--dark-emerald;

      &:hover {
        box-shadow: 0 rem(2) rem(6) rgba(0, 0, 0, 0.3);
        transition: box-shadow 200ms cubic-bezier(0.35, 0, 0.25, 1);
      }
    }

    &.mat-dark-green-transparent {
      color: $color--dark-emerald;
      background-color: rgba($color: $color--white, $alpha: 0);
      border: rem(1) solid $color--dark-emerald;

      &:hover {
        box-shadow: 0 rem(2) rem(6) rgba(0, 0, 0, 0.3);
        transition: box-shadow 200ms cubic-bezier(0.35, 0, 0.25, 1);
      }
    }

    &.mat-green {
      color: $color--white;
      background-color: $color--dark-emerald;

      &:hover {
        box-shadow: 0 rem(2) rem(6) rgba(0, 0, 0, 0.3);
        transition: box-shadow 200ms cubic-bezier(0.35, 0, 0.25, 1);
      }
    }
  }

  .mat-button,
  .mat-raised-button,
  .mat-icon-button,
  .mat-stroked-button,
  .mat-flat-button,
  .mat-fab,
  .mat-mini-fab {
    font-size: rem(14);
  }

  .mat-button,
  .mat-stroked-button,
  .mat-raised-button,
  .mat-flat-button {
    padding: 0 rem(16);
    line-height: rem(36);

    &.mat-primary,
    &.mat-accent,
    &.mat-warn,
    &.mat-button-disabled {
      &.mat-button-disabled {
        color: rgba(0, 0, 0, 0.7);
      }
    }
  }

  .mat-icon-button {
    padding: 0;
    width: rem(40);
    height: rem(40);
    line-height: rem(40);

    i,
    .mat-icon {
      line-height: rem(24);
    }
  }

  .mat-stroked-button {
    line-height: rem(34);
  }

  .mat-menu-item {
    line-height: rem(48);
    height: rem(48);
    font-size: rem(14);
    padding: 0 rem(16);

    .mat-icon {
      height: 30px;
    }
  }

  .mat-gray {
    color: $color--gray;
  }

  .material-icons {
    font-size: rem(24);
  }

  .mat-icon {
    width: rem(24);
    height: rem(24);
  }

  .mat-tooltip {
    font-size: rem(10) !important;

    &.medium-tooltip {
      font-size: rem(12) !important;
      max-width: rem(500);
    }
  }

  .mat-no-border {
    .mat-form-field-underline,
    .mat-form-field-ripple {
      height: 0 !important;
    }

    .mat-form-field-wrapper {
      padding-bottom: rem(7);
    }
  }

  .mat-full-width {
    width: 100%;
  }

  .mat-outline-small {
    .mat-form-field-infix {
      padding: rem(8) 0;
    }

    .mat-form-field-wrapper {
      padding-bottom: 0;
    }

    .mat-form-field-label {
      margin-top: rem(-8);
    }

    &.mat-form-field-can-float.mat-form-field-should-float {
      .mat-form-field-label {
        margin-top: rem(-4);
      }
    }
  }

  .mat-form-field-appearance-outline {
    .mat-form-field-outline {
      color: $color--black-border;
    }

    .mat-form-field-required-marker {
      color: currentColor;
    }

    .mat-form-field-outline-start {
      border-radius: rem(5) 0 0 rem(5);
    }

    .mat-form-field-outline-start,
    .mat-form-field-outline-end {
      min-width: rem(5);
      border-width: rem(1);
    }

    .mat-form-field-flex {
      .mat-form-field-outline {
        .mat-form-field-outline-start,
        .mat-form-field-outline-end,
        .mat-form-field-outline-gap {
          border-width: rem(1);
        }
      }

      .mat-form-field-outline-thick {
        .mat-form-field-outline-start,
        .mat-form-field-outline-end,
        .mat-form-field-outline-gap {
          border-width: rem(1);
        }
      }
    }

    &.mat-focused {
      .mat-form-field-outline {
        color: $color--picto-dark-blue;
      }
    }

    &.color-gray {
      .mat-form-field-outline {
        color: $color--black-border;
      }

      &.mat-focused {
        .mat-form-field-outline {
          color: $color--picto-dark-blue;
        }
      }
    }
  }

  .mat-drawer {
    transition:
      margin-left 0.3s ease,
      margin-right 0.3s ease;
  }

  .mat-sidenav-container {
    min-height: 100vh;

    &.mat-drawer-container {
      overflow: unset;
      z-index: auto;
    }

    .mat-drawer-inner-container {
      overflow-x: unset;

      &::-webkit-scrollbar {
        background-color: transparent;
        width: rem(7);
      }

      &::-webkit-scrollbar-thumb {
        background-color: $color--silver-chalice;
        border-radius: rem(10);
      }
    }
  }

  [appearance='standard'] {
    &.mat-form-field {
      input {
        border: rem(1) solid $color--black-border;
        border-radius: rem(4);
        box-sizing: border-box;
        padding: rem(8) rem(12);
      }

      .mat-form-field-infix {
        padding: 0 !important;
      }

      .mat-form-field-flex {
        padding: 0;
      }

      .mat-form-field-wrapper {
        width: 100%;
      }

      .mat-form-field-infix {
        padding: rem(10) 0 rem(10) 0;
        border-top: 0 solid transparent;
      }

      .mat-form-field-wrapper {
        padding: 0;
        margin: 0;
      }

      .mat-input-element {
        caret-color: $color--black;
      }

      .mat-form-field-outline {
        color: $g-color6 !important;
      }

      .mat-form-field-underline {
        display: none;
      }
    }
  }

  .mat-input-element {
    font: inherit;
    background: transparent;
    color: currentColor;
    border: none;
    outline: none;
    padding: 0;
    margin: 0;
    width: 100%;
    max-width: 100%;
    vertical-align: bottom;
    text-align: inherit;
    box-sizing: content-box;
  }

  .mat-form-field {
    .cards-search-section & {
      .mat-form-field-wrapper {
        padding-bottom: 0;
      }
    }

    &.no-padding {
      > .mat-form-field-wrapper {
        margin-bottom: rem(-20);
      }
    }

    .mat-form-field-infix {
      width: rem(180);
    }
  }

  .mat-button,
  .mat-raised-button,
  .mat-stroked-button {
    &:hover {
      box-shadow: 0 rem(2) rem(6) rgba(0, 0, 0, 0.3);
      transition: box-shadow 200ms cubic-bezier(0.35, 0, 0.25, 1);
    }
  }

  .mat-button,
  .mat-raised-button,
  .mat-stroked-button,
  .mat-flat-button,
  .mat-fab,
  .mat-mini-fab {
    font-family: 'DM Sans', sans-serif !important;

    &:focus {
      outline: 0 !important;
    }
  }

  .mat-snack-bar-container {
    max-width: 100vw !important;

    @media screen and (max-width: rem(400)) {
      min-width: 0 !important;
    }

    &.error {
      color: $color--flory;
      background-color: $color--white;
    }
  }

  .mat-button.mat-primary[disabled],
  .mat-button.mat-accent[disabled],
  .mat-button.mat-warn[disabled],
  .mat-button[disabled][disabled],
  .mat-icon-button.mat-primary[disabled],
  .mat-icon-button.mat-accent[disabled],
  .mat-icon-button.mat-warn[disabled],
  .mat-icon-button[disabled][disabled],
  .mat-stroked-button.mat-primary[disabled],
  .mat-stroked-button.mat-accent[disabled],
  .mat-stroked-button.mat-warn[disabled],
  .mat-stroked-button[disabled][disabled] {
    opacity: 0.6;
  }

  .mat-card :active {
    outline: 0 !important;
  }

  .mat-card {
    font-family: 'DM Sans', sans-serif !important;

    &.version-new-card {
      border-radius: 0;
      box-shadow: 0 rem(2) rem(4) 0 rgba(0, 0, 0, 0.1);
    }
  }

  div {
    &.mat-menu-panel {
      min-height: rem(40);

      .mat-menu-content {
        padding-bottom: 0;
        padding-top: 0;

        button {
          &.mat-menu-item {
            line-height: rem(40);
            height: rem(40);
          }
        }
      }
    }
  }

  .ptl-mat-dialog {
    .mat-dialog-container {
      border-radius: rem(20);
    }

    &.page-publish-dialog {
      .mat-dialog-container {
        outline: none !important;
      }
    }
  }

  .dialog-header {
    .dialog-header__top {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
    }

    .dialog-header__close-icon {
      align-self: center;
      cursor: pointer;
    }

    .dialog-header__logo {
      width: rem(40);
      height: rem(40);
      object-fit: contain;
    }

    .dialog-header-title {
      margin: 0 rem(10);

      span {
        font-weight: 300;
      }
    }

    .dialog-header-title-box {
      flex-flow: column;
    }

    .dialog-header-sub-title {
      font-size: rem(16);
      font-weight: 500;
      margin: 0 rem(10) rem(10);
    }

    .dialog-header-active-tabs {
      display: flex;
      align-items: center;
      padding: 0 rem(41);
      border-bottom: rem(1) solid $color--silver;
    }

    .dialog-header-tab-item {
      padding: rem(9) 0;
      height: rem(40);
      cursor: pointer;
      font-size: rem(16);
      box-sizing: border-box;
      margin: 0 rem(10);
      border-bottom: rem(3) solid transparent;
      color: $color--regent-gray;

      &.is_active {
        color: $color--dark-emerald;
        border-bottom-color: $color--dark-emerald;
      }

      .mat-icon {
        margin-top: rem(-3);
      }
    }
  }

  .mat-select-arrow {
    @include ptl-direction(border, left-width, rem(5));
    @include ptl-direction(border, right-width, rem(5));
    border-top-width: rem(5);
    margin: 0 rem(4);
  }

  .mat-select-arrow-wrapper {
    height: rem(16);
  }

  .mat-radio-container {
    width: rem(20);
    height: rem(20);
  }

  .mat-radio-outer-circle {
    width: rem(20);
    height: rem(20);
    border-width: rem(2);
  }

  .mat-radio-inner-circle {
    width: rem(20);
    height: rem(20);
  }

  .mat-progress-spinner,
  .mat-spinner {
    circle {
      stroke: $g-color11;
    }
  }

  .mat-calendar {
    .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover {
      .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
        background-color: $g-color14;
        color: $g-color13;
      }
    }

    .mat-calendar-body-selected {
      background-color: $g-color13;
      color: $g-color10;
    }

    .mat-calendar-body-in-range {
      &:before {
        background-color: $g-color14;
        color: $g-color13;
      }

      &:after {
        background-color: $g-color14;
        color: $g-color13;
      }

      .mat-calendar-body-cell-content:not(.mat-calendar-body-selected).mat-focus-indicator {
        background-color: $g-color14;
        color: $g-color13;
      }
    }

    .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
      border-color: $g-color6;
    }

    .mat-calendar-body-active {
      &:focus {
        .mat-calendar-body-cell-content:not(.mat-calendar-body-selected).mat-focus-indicator {
          background-color: $g-color14;
          color: $g-color13;
        }
      }
    }
  }

  .mat-form-field-appearance-outline {
    &.mat-focused {
      &:not(.mat-form-field-invalid) {
        .mat-input-element {
          caret-color: $g-color11;
        }

        .mat-form-field-label {
          color: $g-color11;
        }

        .mat-form-field-outline {
          color: $g-color11;
        }
      }
    }

    &.color-gray {
      .mat-form-field-outline {
        color: $g-color1;
      }

      &.mat-focused {
        &:not(.mat-form-field-invalid) {
          .mat-form-field-label {
            color: $g-color11;
          }

          .mat-form-field-outline {
            color: $g-color11;
          }
        }
      }
    }
  }
}

$g-color1: var(--g-color1); // black
$g-color2: var(--g-color2); // neutral--90
$g-color3: var(--g-color3); // neutral--80
$g-color4: var(--g-color4); // neutral--70
$g-color5: var(--g-color5); // neutral--60
$g-color6: var(--g-color6); // neutral--40
$g-color7: var(--g-color7); // neutral--30
$g-color8: var(--g-color8); // neutral--20
$g-color9: var(--g-color9); // neutral--10
$g-color10: var(--g-color10); // white
$g-color11: var(--g-color11); // primary
$g-color12: var(--g-color12); // primary--light
$g-color13: var(--g-color13); // interactive
$g-color14: var(--g-color14); // interactive--light
$g-color15: var(--g-color15); // interactive-very--light
$g-color16: var(--g-color16); // positive
$g-color17: var(--g-color17); // positive--light
$g-color18: var(--g-color18); // highlight
$g-color19: var(--g-color19); // highlight--light
$g-color20: var(--g-color20); // warning
$g-color21: var(--g-color21); // warning--light
$g-color22: var(--g-color22); // admin--light
$g-color23: var(--g-color23); // admin--dark
$g-color24: var(--g-color24); // positive--dark
$g-color25: var(--g-color25); // error
$g-color26: var(--g-color26); // error snack-bar-container

$theme-primary-color: var(--theme-primary-color);
$theme-primary-light-color: var(--theme-primary-light-color);

/*
 * Copyright (C) 2023 - Potentially Ltd
 *
 * Please see distribution for license.
 */

@import 'colors';
@import 'rem';

.mat-checkbox {
  &.cdk-keyboard-focused {
    .mat-checkbox-inner-container {
      outline: $color--dodger-blue solid rem(1) !important;
    }
  }

  .mat-checkbox-inner-container {
    width: rem(20);
    height: rem(20);
  }

  .mat-checkbox-checkmark {
    width: rem(14);
    height: rem(14);
    margin: auto;
  }

  .mat-checkbox-background,
  .mat-checkbox-frame {
    border-radius: rem(4);
  }

  .mat-checkbox-layout {
    .mat-checkbox-label {
      line-height: rem(24);
      white-space: normal;
    }
  }

  .mat-checkbox-frame {
    border-width: rem(2);
    border-color: $g-color6;
  }

  .mat-checkbox-disabled {
    pointer-events: none;
  }

  .mat-checkbox-checkmark {
    fill: $g-color10 !important;
  }

  .mat-checkbox-checkmark-path {
    stroke: $g-color10 !important;
  }

  .mat-checkbox-mixedmark {
    background-color: $g-color10;
  }

  .mat-ripple-element {
    background-color: $g-color10;
  }

  &.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background,
  &.mat-checkbox-checked.mat-primary .mat-checkbox-background {
    background-color: $g-color16;
  }

  &.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
  &.mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: $g-color18;
  }

  &.mat-checkbox-indeterminate.mat-warn .mat-checkbox-background,
  &.mat-checkbox-checked.mat-warn .mat-checkbox-background {
    background-color: $g-color20;
  }

  &.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background,
  &.mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
    background-color: $g-color6;
  }

  &.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
    border-color: $g-color6;
  }

  &.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
  &:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
    background: $g-color17;
  }

  &.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
  &:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
    background: $g-color19;
  }

  &.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
  &:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
    background: $g-color21;
  }
}

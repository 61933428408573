/*
 * Copyright (C) 2023 - Potentially Ltd
 *
 * Please see distribution for license.
 */
@import 'rem';
@import 'colors';

$color--black-text: rgba(0, 0, 0, 0.84);
$color--black-border: rgba(0, 0, 0, 0.3);
$color--red: #e00000;
$color--white: #fff;
$color--black: #000;
$color--gray: #929292;
$color--gray-darker: #5e5b5b;
$color--monday-gray: #323338;
$color--bright-gray: #384047;
$color--gallery: #eeeeee;
$color--light-gray: #edeff0;
$color--middle-gray: #dcd9d9;
$color--river-bed: #3f4a5b;
$color--comet: #5b5979;
$color--light-purple: #e9d3ff;
$color--purple: #6348f6;
$color--purple-darker: #5a27e7;
$color--malibu: #8fdef5;
$color--picto-dark-blue: #007fa5;
$color--sail: #b0def9;
$color--spindle: #bed6ec;
$color--rock-blue: #a5b6d3;
$color--cerulean: #009fe3;
$color--dark-cerulean: #004d6e;
$color--gossip: #baf5a8;
$color--dark-emerald: #0b7e23;
$color--light-emerald: #d3efd9;
$color--sunglow: #fbcc33;
$color--sandy-brown: #f6ac63;
$color--light-sandy-brown: #fdc898;
$color--light-peach: #fee4cc;
$color--vivid-orange: #fc9132;
$color--frostee: #d1efd8;
$color--sushi: #92ac3f;
$color--flory: #ef6381;
$color--bittersweet: #ff7d61;
$color--alabaster-light: #fafafa;
$color--twitter-bg: #000000;
$color--linkedin-bg: #006699;
$color--facebook-bg: #337fff;
$color--pinterest-bg: #cc2127;
$color--purple-light: #8e6efd;
$color--purple-extra-light: #e0d2ff;
$color--lavender-blue: #cbd4f7;
$color--pale-blue: #b0bdf7;
$color--pale-extra-blue: #edeffb;
$color--linkedin: #0077b5;

$color--muted-lavender: #a394b5;
$color--pale-lavender: #e6def0;
$color--lavender-mist: #ddd7f5;
$color--alabaster: #f9f9f9;
$color--silver: #b9b9b9;
$color--alto: #d9d9d9;
$color--catskill-white: #e4eaf2;
$color--mercury: #e9e9e9;
$color--silver-chalice: #a6a6a6;
$color--alto-solid: #dbdbdb;
$color--star-dust: #a3a3a1;
$color--shuttle-gray: #60676d;
$color--emperor: #555;
$color--outer-space: #2d3339;
$color--mine-shaft: #222;
$color--cod-gray: #1a1a1a;
$color--steel-blue: #50505e;
$color--deep-violet: #373677;
$color--antique-white: #faf8f2;
$color--antique-white: #faf8f2;

$color--light-green: #e4f6e9;
$color--green: #54c06b;
$color--burnt-orange: #d9af1c;

$color--orange: #ff5722;
$color--cornflower-blue: #5299f0;
$color--sky-blue: #5299f0;
$color--light-sky-blue: #e0f2fd;
$color--saffron: #f5b540;
$color--camelot: #942e53;
$color--sweet-pink: #fa9e9d;
$color--hit-pink: #ffa976;
$color--cerise: #e03485;
$color--amaranth: #f03d65;
$color--pomegranate: #f44336;
$color--rust-red: #d73b30;
$color--bismark: #466f89;
$color--iron: #d6d8d8;
$color--golden: #fba84e;
$color--golden-tainoi: #ffcf66;
$color--golden-tainoi-darker: #b98105;
$color--apricot-peach: #f9cdb0;
$color--regent-gray: #8e9aa5;
$color--shakespeare: #4bbbca;
$color--blue-green: #297a82;
$color--trendy-pink: #8c639f;
$color--bali-hai: #8398a8;
$color--charlotte: #c9eef9;
$color--seagull: #81cfe6;
$color--link-water: #daeaf6;
$color--white-ice: #dcf2f9;
$color--bay-of-many: #2b3582;
$color--biscay: #162961;
$color--blue-zodiac: #0c1c35;
$color--anakiwa: #a1c1fe;
$color--burnt-sienna: #d27f2a;
$color--viking: #5de0cd;
$color--nevada: #646b70;
$color--porcelain: #f4f6f7;
$color--wedgewood: #469990;
$color--turquoise: #2dd5bb;
$color--teal-green: #008476;
$color--geyser: #dbe1e4;
$color--polo-blue: #94a9cb;
$color--royal-blue: #452bec;
$color--perfume: #d1cafa;
$color--dodger-blue: #0979cf;
$color--dark-orange-red: #dd3500;
$color--darker-orange-red: #dd0000;
$color--deep-sky-blue: #009ece;
$color--deep-sky-dark-blue: #00779a;
$color--christalle: #361163;
$color--gainsboro: #e9e9ed;
$color--blue-yonder: #5576ac;
$color--dark-purple: #3b126f;
$color--positive: #3b9012;
$color--note-yellow: #f7f19b;
$color--note-gray: #e0e0e0;
$color--dark-periwinkle: #665fd1;
$color--light-gray: #f3f4f6;
$color--lighter-gray: #f9fafb;
$color--dark-gray: #9ca3af;

$color--success-light-green: #d0eed9;
$color--warning-light-red: #feded7;

$color-a-aurometalsaurus: #6b7689;
$color-a-celadon-blue: #007fa5;
$color-a-dark-liver: #505050;

$header-height: rem(60);
$medium-container-width: rem(1000);
$small-container-width: rem(900);
$container-width: rem(1120);
$page-container-width: rem(1220);
$full-width: 100%;
$small-card-width: rem(266);
$medium-card-width: rem(410);
$small-card-width-half: rem(133);

$page-analytics-primary-color: $color--dark-periwinkle;

$color--bright-warning: #ce5f5f;
$color--bright-positive: #97ce5f;

$header-icon-color: $g-color3;
$header-icon-active-color: $g-color11;

$color--folio-background: #f2f0f6;
$color-folio-primary: #2e3a57;

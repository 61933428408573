/*
 * Copyright (C) 2023 - Potentially Ltd
 *
 * Please see distribution for license.
 */
@import 'colors';

.cdk-overlay-pane {
  & .mat-snack-bar-container {
    display: flex;
    justify-content: center;
  }

  .error-snackbar {
    background: $g-color26;
    color: $g-color10;
  }

  .success-snackbar {
    background: $g-color16;
    color: $g-color10;
  }

  .error {
    color: $g-color25 !important;
  }
}

/*
 * Copyright (C) 2023 - Potentially Ltd
 *
 * Please see distribution for license.
 */
@import 'root';
@import 'rem';
@import 'fluid';
@import 'colors';
@import 'styleguide';
@import 'variables';

@import './mixins.scss';
@import './angular-material-custom.theme.scss';
@import '~swiper/swiper-bundle.css';
@import './angular-material-overrides.scss';
@import './breakpoints.scss';
@import './medium-editor-overrides.scss';
@import '~ag-grid-community/styles/ag-grid.css';
@import '~ag-grid-community/styles/ag-theme-material.css';
@import '../assets/font/charter-fonts/charter.css';
@import '../assets/font/dm-sans/dmsans.css';
@import '../assets/font/barlow/barlow.css';
@import '../assets/font/crimson-pro/crimson-pro.css';
@import '../assets/font/icomoon/style.css';
@import './print.scss';
@import './components/multi-select';
@import './components/snack-bar';
@import './components/checkbox';
@import './components/button';
@import './components/chips';
@import './components/group-chips';
@import './components/controls';
@import './components/radio';
@import './components/slide-toggle';

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  &[dir='rtl'],
  [dir='rtl'] {
    .content-media__oembed,
    .preview-element {
      div {
        &:first-child {
          .content-media__html--position-LEFT &,
          .content-media__html--position-RIGHT & {
            @include ptl-direction(float, left, null, true);
          }
        }
      }
    }

    blockquote {
      @include ptl-direction(padding, left, rem(30), true);
      @include ptl-direction(padding, right, rem(15), true);
    }

    .bricklayer-column {
      @include ptl-direction(padding, left, rem(0), true);

      .masonry-section__cards-2 & {
        @include ptl-direction(padding, left, rem(0), true);
        @include ptl-direction(margin, left, rem(10), true);
      }

      .masonry-section__cards-4 & {
        @include ptl-direction(margin, left, rem(9), true);
      }

      &:last-child {
        .masonry-section__cards-4 & {
          @include ptl-direction(margin, left, rem(0), true);
        }
      }

      .masonry-section__cards-4 & {
        @include ptl-direction(padding, left, rem(0), true);
        @include ptl-direction(margin, left, rem(10), true);
      }

      @media screen and (max-width: rem(504)) {
        .masonry-section__cards-4 & {
          @include ptl-direction(margin, left, 2%, true);
        }
      }

      @media screen and (max-width: rem(330)) {
        .masonry-section__cards-4 & {
          @include ptl-direction(margin, left, rem(0), true);
        }
      }
    }

    .chart-tooltip {
      .tooltip-content {
        .tooltip-content-inner {
          .tooltip-title {
            @include ptl-direction(padding, left, rem(8), true);
          }

          .tooltip-image {
            @include ptl-direction(margin, left, rem(5), true);
          }
        }
      }
    }

    .swal2-container {
      .swal2-popup {
        &.new-version {
          .swal2-close {
            @include ptl-direction(position, left, rem(16), true);
          }

          .swal2-title {
            @include ptl-direction(text-align, right, null, true);
          }

          .swal2-html-container {
            @include ptl-direction(text-align, right, null, true);
          }
        }
      }
    }

    .content-view-admin-buttons-menu,
    .content-view-admin-buttons-language-menu {
      &.mat-menu-panel {
        .mat-menu-content {
          .mat-menu-item {
            &.button {
              .icon {
                @include ptl-direction(margin, left, rem(4), true);
              }

              &.language-button {
                @include ptl-direction(padding, left, rem(30), true);
              }
            }

            .copy-button {
              ::ng-deep {
                .mat-spinner {
                  @include ptl-direction(margin, left, rem(4), true);
                }
              }
            }
          }
        }
      }
    }
  }

  &[dir='ltr'],
  [dir='ltr'] {
    .content-media__oembed,
    .preview-element {
      div {
        &:first-child {
          .content-media__html--position-LEFT &,
          .content-media__html--position-RIGHT & {
            @include ptl-direction(float, right, null, true);
          }
        }
      }
    }

    blockquote {
      @include ptl-direction(padding, right, rem(30), true);
      @include ptl-direction(padding, left, rem(15), true);
    }

    .bricklayer-column {
      @include ptl-direction(padding, right, rem(0), true);

      .masonry-section__cards-2 & {
        @include ptl-direction(padding, right, rem(0), true);
        @include ptl-direction(margin, right, rem(10), true);
      }

      .masonry-section__cards-4 & {
        @include ptl-direction(margin, right, rem(9), true);
      }

      &:last-child {
        .masonry-section__cards-4 & {
          @include ptl-direction(margin, right, rem(0), true);
        }
      }

      .masonry-section__cards-4 & {
        @include ptl-direction(padding, right, rem(0), true);
        @include ptl-direction(margin, right, rem(10), true);
      }

      @media screen and (max-width: rem(504)) {
        .masonry-section__cards-4 & {
          @include ptl-direction(margin, right, 2%, true);
        }
      }

      @media screen and (max-width: rem(330)) {
        .masonry-section__cards-4 & {
          @include ptl-direction(margin, right, rem(0), true);
        }
      }
    }

    .chart-tooltip {
      .tooltip-content {
        .tooltip-content-inner {
          .tooltip-title {
            @include ptl-direction(padding, right, rem(8), true);
          }

          .tooltip-image {
            @include ptl-direction(margin, right, rem(5), true);
          }
        }
      }
    }

    .swal2-container {
      .swal2-popup {
        &.new-version {
          .swal2-close {
            @include ptl-direction(position, right, rem(16), true);
          }

          .swal2-title {
            @include ptl-direction(text-align, left, null, true);
          }

          .swal2-html-container {
            @include ptl-direction(text-align, left, null, true);
          }
        }
      }
    }

    .content-view-admin-buttons-menu,
    .content-view-admin-buttons-language-menu {
      &.mat-menu-panel {
        .mat-menu-content {
          .mat-menu-item {
            &.button {
              .icon {
                @include ptl-direction(margin, right, rem(4), true);
              }

              &.language-button {
                @include ptl-direction(padding, right, rem(30), true);
              }
            }

            .copy-button {
              ::ng-deep {
                .mat-spinner {
                  @include ptl-direction(margin, right, rem(4), true);
                }
              }
            }
          }
        }
      }
    }
  }
}

body {
  background-color: $g-color9;
  font-family: 'DM Sans', sans-serif;
  font-size: rem(12);
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -webkit-user-modify: read-only;
  overflow-wrap: normal;
  -webkit-line-break: normal;

  .content-view-admin-buttons-menu,
  .content-view-admin-buttons-language-menu {
    &.mat-menu-panel {
      background-color: $g-color12;

      .mat-menu-content {
        .mat-menu-item {
          &.button {
            min-width: 0;
            padding: rem(2) rem(13);
            color: $g-color11;
            box-shadow: none;
            align-items: center;
            display: inline-flex;

            &:hover {
              color: $g-color1;
            }

            .title {
              color: currentColor;
            }

            .icon {
              display: inline-flex;
              align-items: center;
              vertical-align: middle;
              width: rem(20);
              height: rem(20);
              justify-content: center;

              svg {
                fill: currentColor;
              }

              .mat-icon {
                font-size: rem(20);
                width: rem(16);
                height: rem(16);
                line-height: rem(16);
                margin: 0;
                color: inherit;
              }

              [class^='ptl-icon-'],
              [class*=' ptl-icon-'] {
                font-size: rem(17);
                width: rem(16);
                height: rem(16);
                line-height: rem(16);
              }
            }
          }

          .copy-button {
            ::ng-deep {
              .mat-button-wrapper {
                display: flex;
                justify-content: center;
                align-items: center;
              }

              .mat-progress-spinner,
              .mat-spinner {
                circle {
                  stroke: currentColor;
                }
              }
            }
          }
        }
      }
    }
  }

  .content-view-admin-buttons-language-menu {
    &.mat-menu-panel {
      .mat-menu-content {
        .mat-menu-item {
          &.button {
            &.is_active {
              color: $g-color1;
            }
          }
        }
      }
    }
  }

  .bricklayer-column-sizer {
    width: calc(100% / 2);
    display: none;
  }

  .masonry-section__cards {
    justify-content: space-between;
  }

  .mat-drawer-container {
    background-color: $g-color9;
  }

  .ptl-mat-dialog {
    .mat-dialog-container {
      padding: rem(32);
      border-radius: 0;
      box-shadow: 0 rem(4) rem(8) 0 rgba(0, 0, 0, 0.15);
    }
  }

  .language-menu-item {
    &.is_active {
      color: $color--picto-dark-blue;
    }
  }

  &.jisc-connection-dialog-shown {
    .cdk-overlay-container {
      display: none !important;
    }
  }

  &.full-screen-enabled {
    overflow: hidden;

    .header {
      &:before {
        display: block;
        position: absolute;
        z-index: 50;
        top: 0;
        @include ptl-direction(position, right, rem(0), true);
        @include ptl-direction(position, left, rem(0), true);
        bottom: 0;
        content: '';
        background-color: rgba(0, 0, 0, 0.7);
      }
    }
  }
}

.maintenance-layer {
  position: absolute;
  top: 0;
  @include ptl-direction(position, left, rem(0), true);
  @include ptl-direction(position, right, rem(0), true);
  z-index: 10;
  background-color: $color--sunglow;
  padding: rem(10) rem(20);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  .maintenance-layer-inner {
    width: 100%;
    margin: auto;
    text-align: center;

    .large {
      color: $color--black;
      margin: 0;
    }

    .small {
      color: $color--black;
    }

    a {
      color: $color--black;
      text-decoration: none;

      &:hover {
        color: $color--black;
      }
    }
  }
}

img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  aspect-ratio: unset;
}

.svg {
  width: rem(20);
  height: rem(20);
  &.positive {
    fill: $g-color24;
  }

  &.greyed {
    fill: $g-color5;
  }
}

button {
  span {
    &.mat-button-wrapper {
      display: block;
    }
  }
}

.is_hidden {
  display: none !important;
}

.page__container {
  margin: rem(20) 0;
}

.content-media__html-inner {
  iframe {
    margin: 0 !important;
  }
}

.clear {
  clear: both;
}

.ag-root-wrapper-body.ag-layout-normal {
  height: auto;
}

.content-media__oembed,
.preview-element {
  div {
    &:first-child {
      margin: 0 auto;

      .content-media__html--position-LEFT &,
      .content-media__html--position-RIGHT & {
        width: 100%;

        @include breakpoint(mobileOnly) {
          width: 100% !important;
        }
      }

      @include breakpoint(mobileOnly) {
        width: 100% !important;
      }
    }
  }
}

.mat-tooltip-panel {
  pointer-events: none;
}

.mat-tooltip.more-info {
  max-width: rem(500);
  font-size: rem(13) !important;
  background-color: $color--emperor;
}

.iframe-container {
  padding-bottom: 0;

  & > .iframely-responsive {
    height: auto;
    padding: 0;
    position: static;

    & > div {
      position: static;
    }

    & > .iframely-embed {
      position: static;
      width: auto;
      height: auto;

      .iframely-responsive {
      }
    }
  }
}

.content-media__oembed > .iframely-embed {
  position: relative;
  z-index: 1;
}

.table {
  display: table !important;
  width: 100% !important;
}

.table-cell {
  display: table-cell !important;

  &.hide {
    display: none !important;
  }
}

.table-row {
  display: table-row !important;
}

.is_disabled {
  opacity: 0.8;
  pointer-events: none;
}

.is_visible {
  visibility: visible !important;
}

[hidden] {
  display: none !important;
}

.uppercase {
  text-transform: uppercase;
}

.flex {
  display: flex !important;
}

.inline-flex {
  display: inline-flex !important;
}

.back-drop-transparent {
  background-color: transparent;
}

.ptl-input {
  border: none;
  outline: none;

  &::placeholder {
    color: $color--regent-gray;
  }
}

.preview-element-position-CENTER {
  img {
    width: 100%;
  }
}

.submission-locked-warning {
  max-width: $container-width;
  box-shadow: 0 0 rem(3) rgb(0 0 0 / 16%);
  margin: rem(4) auto rem(12);
  border-radius: rem(8);
  background-color: $g-color21;
  position: sticky;
  top: rem(50);
  text-align: center;
  padding: rem(8) rem(20);
  z-index: 20;
  left: 0;
  right: 0;
  @extend .small;
}

.show-focus {
  *[tabindex],
  button {
    &:focus {
      outline: none;
    }

    &:focus-visible {
      outline: $color--dodger-blue solid rem(1) !important;
    }

    &:-moz-focusring {
      outline: $color--dodger-blue solid rem(1) !important;
    }
  }

  a {
    &:focus {
      outline: none;
    }

    &:focus-visible {
      outline: $color--dodger-blue solid rem(1) !important;
    }

    &:-moz-focusring {
      outline: $color--dodger-blue solid rem(1);
    }
  }
}

blockquote {
  @include ptl-direction(border, left, rem(5) solid $color--silver);
  color: $color--black;
  padding-top: rem(0);
  padding-bottom: rem(0);
  margin: 0;
  font-style: italic;
  line-height: rem(22);
}

.show-focus {
  input[type='range'] {
    -webkit-appearance: none;
    width: 100%;
    margin: rem(8) 0;

    &:focus {
      outline: none;

      &::-ms-fill-lower {
        background: #ffffff;
      }

      &::-ms-fill-upper {
        background: #ffffff;
      }

      &::-webkit-slider-runnable-track {
        background: #ffffff;
      }
    }
  }
}

input[type='range'] {
  -webkit-appearance: none;
  width: 100%;
  margin: rem(8) 0;

  &::-webkit-slider-runnable-track {
    width: 100%;
    height: rem(6);
    cursor: pointer;
    box-shadow: none;
    background: #ffffff;
    border-radius: rem(5);
    border: none;
  }

  &::-webkit-slider-thumb {
    box-shadow: none;
    border: rem(3) solid $color--bali-hai;
    height: rem(22);
    width: rem(22);
    border-radius: rem(22);
    background: #ffffff;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: rem(-8.1);
  }

  &::-moz-range-track {
    width: 100%;
    height: rem(6);
    cursor: pointer;
    box-shadow: none;
    background: #ffffff;
    border-radius: rem(5);
    border: rem(0.1) solid #000000;
  }

  &::-moz-range-thumb {
    box-shadow: none;
    border: rem(3) solid $color--bali-hai;
    height: rem(22);
    width: rem(22);
    border-radius: rem(22);
    background: #ffffff;
    cursor: pointer;
  }

  &::-ms-track {
    width: 100%;
    height: rem(6);
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }

  &::-ms-fill-lower {
    background: #ffffff;
    border: rem(0.1) solid #000000;
    border-radius: rem(10);
  }

  &::-ms-fill-upper {
    background: #ffffff;
    border: none;
    border-radius: rem(10);
  }

  &::-ms-thumb {
    border: rem(3) solid $color--bali-hai;
    width: rem(22);
    border-radius: rem(22);
    background: #ffffff;
    cursor: pointer;
    height: rem(6);
  }
}

textarea {
  outline: none;
  font-family: 'DM Sans', sans-serif;
}

input:-webkit-autofill {
  box-shadow: white 0 0 0 rem(30) inset !important;
}

ptl-editor {
  outline: none;

  &.no-outline {
    .tinymce-editor {
      .mce-content-body {
        &.mce-edit-focus {
          outline: none;
        }
      }
    }
  }
}

.bricklayer-column {
  max-width: 48%;
  width: 100%;

  .masonry-section__cards-4 & {
    box-sizing: border-box;
    width: $small-card-width;
  }

  .masonry-section__cards-2 & {
    box-sizing: border-box;
    width: 100%;
  }

  .moment-card {
    max-width: 100%;
  }

  @media screen and (max-width: rem(504)) {
    .masonry-section__cards-4 & {
      width: 48%;
    }
  }

  @media screen and (max-width: rem(330)) {
    .masonry-section__cards-4 & {
      width: 100%;
    }
  }

  @include breakpoint(tabletPortraitOnly) {
    max-width: 100%;
  }
}

ptl-diagnostics-view-content,
ptl-diagnostics-view-questions,
ptl-diagnostics-view-report {
  flex: 1;
  width: 100%;
}

.dialog-backdrop {
  background: rgba(0, 0, 0, 0.7);
}

.cdk-overlay-pane[style*='width: 28.125rem'] {
  .mat-autocomplete-panel {
    .mat-option {
      min-height: rem(40);
      white-space: normal;
      overflow: visible;
      line-height: rem(24);
      padding: rem(12);
      height: auto;
    }

    .mat-option-text {
      font-size: rem(14);
    }
  }

  @media screen and (max-width: rem(450)) {
    width: rem(300) !important;
  }
}

.chart-tooltip {
  position: absolute;
  z-index: 20;
  pointer-events: none;

  .tooltip-content {
    list-style: none;
    padding: 0;
    margin: 0;

    .tooltip-content-inner {
      padding: rem(3);
      border-radius: rem(3);
      background-color: $color--white;
      color: $color--black;
      font-size: rem(15);
      display: flex;
      align-items: center;
      flex-flow: row;

      .tooltip-title {
        display: flex;
        align-items: center;
        line-height: rem(22);

        & > span {
          display: inline-block;
          vertical-align: middle;
          margin: 0 rem(7);
          width: rem(14);
          height: rem(14);
          line-height: rem(14);
          border: rem(2) solid transparent;
          box-sizing: border-box;

          &.no-border {
            border: none;
          }

          span {
            display: block;
            width: 100%;
            height: 100%;
            position: relative;
            z-index: 10;
          }
        }
      }

      .tooltip-image {
        width: rem(50);
        height: rem(50);
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.swal2-container {
  .swal2-popup {
    border-radius: rem(20);

    &.new-version {
      border-radius: 0;
      padding: rem(32);
      box-shadow: 0 rem(4) rem(8) 0 rgba(0, 0, 0, 0.15);
      width: 100%;
      max-width: rem(686);

      .swal2-icon {
        &:empty {
          & ~ .swal2-html-container {
            margin-top: 0;
          }
        }
      }

      .swal2-close {
        width: rem(36);
        height: rem(36);
        line-height: rem(36);
        padding: 0;
        display: inline-flex;
        vertical-align: middle;
        align-items: center;
        color: var(--primary-button-hover-color);
        justify-content: center;
        border-radius: 50%;
        box-shadow: none;
        position: absolute;
        top: rem(16);

        &:hover {
          opacity: 0.7;
          background-color: var(--primary-button-hover-bg);
        }

        svg {
          width: rem(32);
          height: rem(32);
        }
      }

      .swal2-title {
        font-family: 'DM Sans', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: rem(20);
        line-height: rem(26);
        margin: 0 0 rem(12);
        color: $g-color1;
        padding: 0;
      }

      .swal2-html-container {
        margin: rem(16) 0 0;
        font-family: 'DM Sans', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: rem(16);
        line-height: rem(24);
        color: $g-color1;

        h2 {
          font-family: 'DM Sans', sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: rem(20);
          line-height: rem(26);
          margin: 0 0 rem(12);
          color: $g-color1;
        }
      }

      .swal2-actions {
        width: 100%;
        justify-content: flex-end;
      }

      .dialog-title {
        margin: 0;
      }

      .dialog-description {
        margin: 0;
      }

      .swal2-cancel {
        &.swal2-styled {
          transition:
            background 400ms cubic-bezier(0.25, 0.8, 0.25, 1),
            color 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
          min-width: rem(68);
          height: rem(36);
          padding: rem(8) rem(18);
          box-sizing: border-box;
          border-radius: rem(4);
          font-weight: 700;
          font-size: rem(13);
          line-height: rem(20);
          cursor: pointer;
          letter-spacing: 0;
          font-family: 'DM Sans', sans-serif;
          color: var(--secondary-button-color);
          background-color: var(--secondary-button-bg);
          border: rem(1) solid var(--secondary-button-hover-bg);
          background-image: none;
          box-shadow: none;

          &:hover {
            color: var(--secondary-button-hover-color);
            background-color: var(--secondary-button-hover-bg);
          }

          &:focus {
            border: 1px solid $theme-primary-color;
          }
        }
      }

      .swal2-confirm {
        &.swal2-styled {
          transition:
            background 400ms cubic-bezier(0.25, 0.8, 0.25, 1),
            color 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
          min-width: rem(68);
          height: rem(36);
          padding: rem(8) rem(18);
          box-sizing: border-box;
          border-radius: rem(4);
          font-weight: 700;
          font-size: rem(13);
          line-height: rem(20);
          cursor: pointer;
          letter-spacing: 0;
          font-family: 'DM Sans', sans-serif;
          color: var(--primary-button-color);
          background-color: var(--primary-button-bg);
          background-image: none;
          box-shadow: none;
          border-width: 1px;
          border-style: solid;

          &:hover {
            color: var(--primary-button-hover-color);
            background-color: var(--primary-button-hover-bg);
          }

          &:focus {
            border: 1px solid $theme-primary-color;
          }
        }
      }
    }
  }

  .swal2-cancel {
    &.swal2-styled {
      background-color: $color--silver;

      &:focus {
        box-shadow: none;
      }

      &:hover {
        box-shadow: 0 rem(2) rem(6) rgba(0, 0, 0, 0.3);
        transition: box-shadow 200ms cubic-bezier(0.35, 0, 0.25, 1);
      }

      &:focus {
        border: 1px solid;
      }
    }
  }

  .swal2-confirm {
    &.swal2-styled {
      background-color: $color--cerulean;

      &:focus {
        box-shadow: none;
      }

      &:hover {
        box-shadow: 0 rem(2) rem(6) rgba(0, 0, 0, 0.3);
        transition: box-shadow 200ms cubic-bezier(0.35, 0, 0.25, 1);
      }

      &:focus {
        border: rem(1) solid $theme-primary-color;
      }
    }
  }

  .jisc-connection-dialog {
    width: 100%;
    max-width: rem(630);

    .swal2-image {
      max-width: rem(80);
      margin: rem(32) rem(24) rem(16);
    }

    .swal2-html-container {
      @include breakpoint(mobileOnly) {
        margin-top: 0;
      }
    }
  }
}

@keyframes materialErrorAnimation {
  0% {
    opacity: 0;
    transform: translateY(-5);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

:root {
  --g-color1: #222222; // black
  --g-color2: #3a394c; // neutral--90
  --g-color3: #50505e; // neutral--80
  --g-color4: #6c6c78; // neutral--70
  --g-color5: #838391; // neutral--60
  --g-color6: #bebec5; // neutral--40
  --g-color7: #d9d9dd; // neutral--30
  --g-color8: #e9e9ed; // neutral--20
  --g-color9: #f2f2f3; // neutral--10
  --g-color10: #ffffff; // white
  --g-color11: #3b126f; // primary
  --g-color12: #e6def0; // primary--light
  --g-color13: #373677; // interactive
  --g-color14: #e7eaf6; // interactive--light
  --g-color15: #f6f7fb; // interactive-very--light
  --g-color16: #3b9012; // positive
  --g-color17: #d9edd7; // positive--light
  --g-color18: #d9af1c; // highlight
  --g-color19: #f6f7dd; // highlight--light
  --g-color20: #7d260b; // warning
  --g-color21: #ffe2c7; // warning--light
  --g-color22: #ffeb7b; // admin--light
  --g-color23: #231a37; // admin--dark
  --g-color24: #08583b; // positive--dark
  --g-color25: #e00000; // error
  --g-color26: #e05151; // error snack-bar
  --g-color27: #fc9132; // orange
  --g-color28: #e05151; // error snack-bar
  --g-color29: #fc9132; // orange
  --g-color30: #f6ac63; // light orange
  --theme-primary-color: #5a27e7; // potentially primary
  --theme-primary-light-color: #ddd7f5; // potentially primary light

  --potentially-button-bg: var(--theme-primary-color);
  --primary-button-bg: var(--g-color11);
  --primary-button-hover-bg: var(--g-color12);
  --primary-button-color: var(--g-color10);
  --primary-button-hover-color: var(--g-color11);
  --secondary-button-bg: var(--g-color10);
  --secondary-button-hover-bg: var(--g-color6);
  --secondary-button-color: var(--g-color1);
  --secondary-button-hover-color: var(--g-color1);
  --tertiary-button-bg: var(--g-color7);
  --tertiary-button-hover-bg: var(--g-color6);
  --tertiary-button-color: var(--g-color1);
  --tertiary-button-hover-color: var(--g-color1);
  --warning-button-bg: var(--g-color20);
  --warning-button-hover-bg: var(--g-color21);
  --warning-button-color: var(--g-color10);
  --warning-button-hover-color: var(--g-color20);
  --positive-button-color: var(--g-color10);
  --positive-button-bg: var(--g-color16);
  --positive-button-hover-color: var(--g-color16);
  --positive-button-hover-bg: var(--g-color16);
  --orange-button-color: var(--g-color10);
  --orange-button-bg: var(--g-color29);
  --orange-button-hover-bg: var(--g-color30);
  --orange-button-hover-color: var(--g-color10);
}

/*
 * Copyright (C) 2023 - Potentially Ltd
 *
 * Please see distribution for license.
 */
@import 'rem';
@import '~medium-editor/dist/css/medium-editor.min.css';
@import '~medium-editor/dist/css/themes/default.min.css';

@-webkit-keyframes toolbar-pop-upwards {
  0% {
    opacity: 0;
    -webkit-transform: matrix(0.97, 0, 0, 1, 0, 12);
    transform: matrix(0.97, 0, 0, 1, 0, 12);
  }
  20% {
    opacity: 0.7;
    -webkit-transform: matrix(0.99, 0, 0, 1, 0, 2);
    transform: matrix(0.99, 0, 0, 1, 0, 2);
  }
  40% {
    opacity: 1;
    -webkit-transform: matrix(1, 0, 0, 1, 0, -1);
    transform: matrix(1, 0, 0, 1, 0, -1);
  }
  100% {
    -webkit-transform: matrix(1, 0, 0, 1, 0, 0);
    transform: matrix(1, 0, 0, 1, 0, 0);
  }
}

@keyframes toolbar-pop-upwards {
  0% {
    opacity: 0;
    -webkit-transform: matrix(0.97, 0, 0, 1, 0, 12);
    transform: matrix(0.97, 0, 0, 1, 0, 12);
  }
  20% {
    opacity: 0.7;
    -webkit-transform: matrix(0.99, 0, 0, 1, 0, 2);
    transform: matrix(0.99, 0, 0, 1, 0, 2);
  }
  40% {
    opacity: 1;
    -webkit-transform: matrix(1, 0, 0, 1, 0, -1);
    transform: matrix(1, 0, 0, 1, 0, -1);
  }
  100% {
    -webkit-transform: matrix(1, 0, 0, 1, 0, 0);
    transform: matrix(1, 0, 0, 1, 0, 0);
  }
}

.medium-editor-element {
  & > p {
    word-break: break-word;
  }
}

.medium-editor-placeholder-relative,
.medium-editor-placeholder {
  &:after {
    padding: 0 !important;
    line-height: rem(32) !important;
    font-size: rem(21) !important;
    font-style: normal !important;
    margin: 0 !important;
    font-family: 'charter-regular', sans-serif !important;
  }
}

.tox-tinymce-aux {
  &.tox {
    .tox-pop {
      max-width: unset !important;

      &:before,
      &:after {
        display: none;
      }

      .tox-pop__dialog {
        background-color: $g-color22;
        border-radius: rem(8);
        box-shadow: none;
        border: none;

        .tox-toolbar {
          padding: rem(4);
          background-image: none;
          background-position: unset;
          background-size: unset;
          margin: 0;
          border: none;
        }
      }
    }

    .tox-toolbar__group {
      padding: 0;
      flex-wrap: nowrap;

      &:only-child {
        .tox-tbtn {
          &:nth-child(4) {
            .tox-icon {
              svg {
                display: none;
              }

              &:before {
                position: absolute;
                content: '';
                top: 50%;
                @include ptl-direction(position, left, 50%, true);
                transform: translate(-50%, -50%);
                width: rem(24);
                height: rem(24);
                background: rgba(0, 0, 0, 0)
                  url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPG1hc2sgaWQ9Im1hc2swXzU0MF8xMjI5NSIgc3R5bGU9Im1hc2stdHlwZTphbHBoYSIgbWFza1VuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeD0iMCIgeT0iMCIgd2lkdGg9IjIwIiBoZWlnaHQ9IjIwIj4KPHJlY3Qgd2lkdGg9IjIwIiBoZWlnaHQ9IjIwIiBmaWxsPSIjRDlEOUQ5Ii8+CjwvbWFzaz4KPGcgbWFzaz0idXJsKCNtYXNrMF81NDBfMTIyOTUpIj4KPHBhdGggZD0iTTkgMTRINkM0Ljg4ODY3IDE0IDMuOTQ0MzMgMTMuNjExIDMuMTY3IDEyLjgzM0MyLjM4OSAxMi4wNTU3IDIgMTEuMTExMyAyIDEwQzIgOC44ODg2NyAyLjM4OSA3Ljk0NDMzIDMuMTY3IDcuMTY3QzMuOTQ0MzMgNi4zODkgNC44ODg2NyA2IDYgNkg5VjcuNUg2QzUuMzA1MzMgNy41IDQuNzE1IDcuNzQzIDQuMjI5IDguMjI5QzMuNzQzIDguNzE1IDMuNSA5LjMwNTMzIDMuNSAxMEMzLjUgMTAuNjk0NyAzLjc0MyAxMS4yODUgNC4yMjkgMTEuNzcxQzQuNzE1IDEyLjI1NyA1LjMwNTMzIDEyLjUgNiAxMi41SDlWMTRaTTcgMTAuNzVWOS4yNUgxM1YxMC43NUg3Wk0xMSAxNFYxMi41SDE0QzE0LjY5NDcgMTIuNSAxNS4yODUgMTIuMjU3IDE1Ljc3MSAxMS43NzFDMTYuMjU3IDExLjI4NSAxNi41IDEwLjY5NDcgMTYuNSAxMEMxNi41IDkuMzA1MzMgMTYuMjU3IDguNzE1IDE1Ljc3MSA4LjIyOUMxNS4yODUgNy43NDMgMTQuNjk0NyA3LjUgMTQgNy41SDExVjZIMTRDMTUuMTExMyA2IDE2LjA1NTcgNi4zODkgMTYuODMzIDcuMTY3QzE3LjYxMSA3Ljk0NDMzIDE4IDguODg4NjcgMTggMTBDMTggMTEuMTExMyAxNy42MTEgMTIuMDU1NyAxNi44MzMgMTIuODMzQzE2LjA1NTcgMTMuNjExIDE1LjExMTMgMTQgMTQgMTRIMTFaIiBmaWxsPSIjMjIyMjIyIi8+CjwvZz4KPC9zdmc+Cg==')
                  no-repeat scroll center center;
                background-size: contain;
              }
            }
          }

          &:nth-child(5) {
            .tox-icon {
              svg {
                display: none;
              }

              &:before {
                position: absolute;
                content: '';
                top: 50%;
                @include ptl-direction(position, left, 50%, true);
                transform: translate(-50%, -50%);
                width: rem(24);
                height: rem(24);
                background: rgba(0, 0, 0, 0)
                  url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPG1hc2sgaWQ9Im1hc2swXzU2OF8xMjg2NiIgc3R5bGU9Im1hc2stdHlwZTphbHBoYSIgbWFza1VuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeD0iMCIgeT0iMCIgd2lkdGg9IjIwIiBoZWlnaHQ9IjIwIj4KPHJlY3Qgd2lkdGg9IjIwIiBoZWlnaHQ9IjIwIiBmaWxsPSIjRDlEOUQ5Ii8+CjwvbWFzaz4KPGcgbWFzaz0idXJsKCNtYXNrMF81NjhfMTI4NjYpIj4KPHBhdGggZD0iTTEyLjU4MyA5LjA4M0gxNC45MTdWNi43NUgxMi41ODNWOS4wODNaTTUuMDgzMDEgOS4wODNINy40MTcwMVY2Ljc1SDUuMDgzMDFWOS4wODNaTTEyLjM5NiAxNC4xNjdMMTQuMDYyIDEwLjgzM0gxMC44MzNWNUgxNi42NjdWMTAuNzkyTDE0Ljk3OSAxNC4xNjdIMTIuMzk2Wk00Ljg5NjAxIDE0LjE2N0w2LjU2MjAxIDEwLjgzM0gzLjMzMzAxVjVIOS4xNjcwMVYxMC43OTJMNy40NzkwMSAxNC4xNjdINC44OTYwMVoiIGZpbGw9IiMyMjIyMjIiLz4KPC9nPgo8L3N2Zz4K')
                  no-repeat scroll center center;
                background-size: contain;
              }
            }
          }

          &:nth-last-child(1) {
            .tox-icon {
              svg {
                display: none;
              }

              &:before {
                position: absolute;
                content: '';
                top: 50%;
                @include ptl-direction(position, left, 50%, true);
                transform: translate(-50%, -50%);
                width: rem(24);
                height: rem(24);
                background: rgba(0, 0, 0, 0)
                  url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPG1hc2sgaWQ9Im1hc2swXzU2OF8xMjg2NyIgc3R5bGU9Im1hc2stdHlwZTphbHBoYSIgbWFza1VuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeD0iMCIgeT0iMCIgd2lkdGg9IjIwIiBoZWlnaHQ9IjIwIj4KPHJlY3Qgd2lkdGg9IjIwIiBoZWlnaHQ9IjIwIiBmaWxsPSIjRDlEOUQ5Ii8+CjwvbWFzaz4KPGcgbWFzaz0idXJsKCNtYXNrMF81NjhfMTI4NjcpIj4KPHBhdGggZD0iTTEwLjQ3OSA4LjM3NDk5TDcuMTI1IDUuMDIwOTlIMTZWNy4wMjA5OUgxMS4wNjJMMTAuNDc5IDguMzc0OTlaTTE2IDE4LjE0Nkw5LjIwOCAxMS4zNTRMNy42MjUgMTUuMDIxSDUuNDU4TDcuNjg4IDkuODMyOTlMMS44NzUgNC4wMjA5OUwyLjkzOCAyLjk1Nzk5TDE3LjA2MiAxNy4wODNMMTYgMTguMTQ2WiIgZmlsbD0iIzIyMjIyMiIvPgo8L2c+Cjwvc3ZnPgo=')
                  no-repeat scroll center center;
                background-size: contain;
              }
            }
          }
        }
      }
    }

    .tox-tbtn {
      width: rem(48) !important;
      height: rem(48);
      box-sizing: border-box;
      cursor: pointer;
      padding: rem(12);
      color: $g-color1;
      background-color: $g-color22;
      margin: 0;
      border-radius: rem(8);
      transition:
        color ease 0.3s,
        background-color ease 0.3s;
      user-select: none;

      &:hover {
        color: $g-color10;
        background-color: $g-color23;

        svg {
          fill: $g-color10;
        }

        .tox-icon {
          &:before {
            filter: brightness(0) invert(1);
          }
        }
      }

      .tox-icon {
        position: relative;
        width: rem(24);
        height: rem(24);
      }

      svg {
        fill: $g-color1;
        width: rem(24);
        height: rem(24);
        display: block;
        margin: 0 auto;
      }
    }

    .tox-tbtn--select {
      width: auto !important;

      svg {
        width: auto;
        height: auto;
      }
    }

    .tox-split-button {
      margin: 0;
      border-radius: 0;
      box-shadow: none;

      &:hover {
        box-shadow: none;

        .tox-split-button__chevron {
          &:before {
            filter: brightness(0) invert(1);
          }
        }
      }

      .tox-tbtn {
        &:not(.tox-split-button__chevron) {
          display: none;
        }
      }

      .tox-split-button__chevron {
        position: relative;

        svg {
          display: none;
        }

        &:before {
          position: absolute;
          content: '';
          top: 50%;
          @include ptl-direction(position, left, 50%, true);
          transform: translate(-50%, -50%);
          width: rem(24);
          height: rem(24);
          background: rgba(0, 0, 0, 0)
            url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPG1hc2sgaWQ9Im1hc2swXzU2OF8xMjg2NSIgc3R5bGU9Im1hc2stdHlwZTphbHBoYSIgbWFza1VuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeD0iMCIgeT0iMCIgd2lkdGg9IjIwIiBoZWlnaHQ9IjIwIj4KPHJlY3Qgd2lkdGg9IjIwIiBoZWlnaHQ9IjIwIiBmaWxsPSIjRDlEOUQ5Ii8+CjwvbWFzaz4KPGcgbWFzaz0idXJsKCNtYXNrMF81NjhfMTI4NjUpIj4KPHBhdGggZD0iTTEwIDE3QzguMjY0IDE3IDYuNzg4MzMgMTYuMzk5MyA1LjU3MyAxNS4xOThDNC4zNTc2NyAxMy45OTY3IDMuNzUgMTIuNTQxNyAzLjc1IDEwLjgzM0MzLjc1IDkuOTk5NjcgMy45MDYzMyA5LjIxODY3IDQuMjE5IDguNDlDNC41MzE2NyA3Ljc2MDY3IDQuOTU4NjcgNy4xMTggNS41IDYuNTYyTDEwIDJMMTQuNTIxIDYuNTgzQzE1LjA0ODMgNy4xMzkgMTUuNDY4MyA3Ljc3OCAxNS43ODEgOC41QzE2LjA5MzcgOS4yMjIgMTYuMjUgOS45OTk2NyAxNi4yNSAxMC44MzNDMTYuMjUgMTIuNTQxNyAxNS42NDIzIDEzLjk5NjcgMTQuNDI3IDE1LjE5OEMxMy4yMTE3IDE2LjM5OTMgMTEuNzM2IDE3IDEwIDE3Wk0xMCAxNS41VjQuMTQ2TDYuNTYyIDcuNjI1QzYuMTMyIDguMDY5NjcgNS44MDU2NyA4LjU2MjY3IDUuNTgzIDkuMTA0QzUuMzYxIDkuNjQ2IDUuMjUgMTAuMjIyMyA1LjI1IDEwLjgzM0M1LjI1IDEyLjEyNSA1LjcxMTY3IDEzLjIyNTcgNi42MzUgMTQuMTM1QzcuNTU5IDE1LjA0NSA4LjY4MDY3IDE1LjUgMTAgMTUuNVoiIGZpbGw9IiMyMjIyMjIiLz4KPC9nPgo8L3N2Zz4K')
            no-repeat scroll center center;
          background-size: contain;
        }
      }
    }
  }
}

/*
 * Copyright (C) 2023 - Potentially Ltd
 *
 * Please see distribution for license.
 */

@use '@angular/material' as mat;
@include mat.core();

$custom-theme-primary: mat.define-palette(mat.$light-blue-palette);
$custom-theme-accent: mat.define-palette(mat.$deep-orange-palette);
$custom-theme-warn: mat.define-palette(mat.$red-palette);

$custom-theme: mat.define-light-theme(
  (
    color: (
      primary: $custom-theme-primary,
      accent: $custom-theme-accent,
      warn: $custom-theme-warn,
    ),
    typography: mat.define-typography-config(),
    density: 0,
  )
);

@include mat.all-component-themes($custom-theme);

@import 'breakpoints';
@import 'rem';
@import 'fluid';
@import 'colors';

.extra-large {
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: rem(28);
  line-height: rem(36);
  color: $g-color1;

  //@include breakpoint(desktopLarge) {
  //  font-size: fluidSize(28, 18);
  //  line-height: fluidSize(36, 26);
  //}

  @include breakpoint(mobilePortraitonly) {
    font-size: rem(18);
    line-height: rem(26);
  }
}

.large {
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: rem(20);
  line-height: rem(26);
  color: $g-color1;

  &.italic {
    font-style: italic;
  }

  //@include breakpoint(desktopLarge) {
  //  font-size: fluidSize(20, 16);
  //  line-height: fluidSize(26, 22);
  //}

  @include breakpoint(mobilePortraitonly) {
    font-size: rem(16);
    line-height: rem(22);
  }
}

.medium {
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: rem(16);
  line-height: rem(24);
  color: $g-color1;

  &.bold {
    font-weight: 700;
  }

  &.italic {
    font-style: italic;
  }

  //@include breakpoint(desktopLarge) {
  //  font-size: fluidSize(16, 15);
  //  line-height: fluidSize(24, 20);
  //}

  @include breakpoint(mobilePortraitonly) {
    font-size: rem(15);
    line-height: rem(20);
  }
}

.medium-big {
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: rem(18);
  line-height: rem(24);
  color: $g-color1;

  &.bold {
    font-weight: 700;
  }

  //@include breakpoint(desktopLarge) {
  //  font-size: fluidSize(16, 15);
  //  line-height: fluidSize(24, 20);
  //}

  @include breakpoint(mobilePortraitonly) {
    font-size: rem(15);
    line-height: rem(20);
  }
}

.small {
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: rem(14);
  line-height: rem(22);
  color: $g-color1;

  //@include breakpoint(desktopLarge) {
  //  font-size: fluidSize(14, 13);
  //  line-height: fluidSize(22, 17);
  //}

  @include breakpoint(mobilePortraitonly) {
    font-size: rem(13);
    line-height: rem(17);
  }

  &.bold {
    font-weight: 700;
  }
}

.extra-small {
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: rem(13);
  line-height: rem(20);
  color: $g-color1;

  //@include breakpoint(desktopLarge) {
  //  font-size: fluidSize(13, 12);
  //  line-height: fluidSize(20, 16);
  //}

  @include breakpoint(mobilePortraitonly) {
    font-size: rem(12);
    line-height: rem(16);
  }

  &.disabled {
    color: rgba(0, 0, 0, 0.38);
  }

  &.italic {
    font-style: italic;
  }

  &.bold {
    font-weight: 700;
  }
}

.extra-extra-small {
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: rem(10);
  line-height: rem(16);
  color: $g-color1;

  @include breakpoint(mobilePortraitonly) {
    font-size: rem(8);
    line-height: rem(12);
  }

  &.disabled {
    color: rgba(0, 0, 0, 0.38);
  }

  &.italic {
    font-style: italic;
  }

  &.bold {
    font-weight: 700;
  }
}

.serif-medium {
  font-family: 'Crimson Pro', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: rem(18);
  line-height: rem(27);
  color: $g-color1;

  //@include breakpoint(desktopLarge) {
  //  font-size: fluidSize(18, 14);
  //  line-height: fluidSize(27, 20);
  //}

  @include breakpoint(mobilePortraitonly) {
    font-size: rem(14);
    line-height: rem(20);
  }

  &.italic {
    font-style: italic;
  }
}

/*
 * Copyright (C) 2023 - Potentially Ltd
 *
 * Please see distribution for license.
 */
@import 'colors';
@import 'rem';

.checkmark {
  box-sizing: border-box;
  border-radius: rem(4);
  cursor: pointer;
  width: rem(20);
  height: rem(20);
  border: rem(2) solid $g-color6;
  fill: none;

  &.is_checked {
    background-color: $g-color16;
    fill: $g-color10;
    border: none;
  }
}

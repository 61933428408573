@import 'variables';
@import 'colors';

.mat-multi-select-custom {
  .mat-pseudo-checkbox-checked,
  .mat-pseudo-checkbox-indeterminate,
  .mat-accent .mat-pseudo-checkbox-checked,
  .mat-accent .mat-pseudo-checkbox-indeterminate {
    background: $color--dark-emerald;
  }
}

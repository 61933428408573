/*
 * Copyright (C) 2023 - Potentially Ltd
 *
 * Please see distribution for license.
 */
@import 'variables';
@import 'colors';
@import 'rem';

body {
  &[dir='rtl'],
  [dir='rtl'] {
    .mat-radio-group {
      .mat-radio-button {
        .mat-radio-label-content {
          @include ptl-direction(padding, right, rem(8), true);
        }
      }
    }
  }

  &[dir='ltr'],
  [dir='ltr'] {
    .mat-radio-group {
      .mat-radio-button {
        .mat-radio-label-content {
          @include ptl-direction(padding, left, rem(8), true);
        }
      }
    }
  }

  .mat-radio-group {
    .mat-radio-button {
      display: block;
      margin-bottom: rem(10);

      &.mat-primary {
        &.mat-radio-checked {
          .mat-radio-persistent-ripple {
            background-color: $g-color16;
          }
        }

        .mat-radio-ripple {
          .mat-ripple-element {
            &:not(.mat-radio-persistent-ripple) {
              background-color: $g-color16;
            }
          }
        }
      }

      &.mat-radio-checked {
        .mat-radio-inner-circle {
          transform: scale(1);
        }

        .mat-radio-outer-circle {
          border-color: $g-color16;
        }
      }

      .mat-radio-outer-circle {
        border: rem(2) solid $g-color6;
        background-color: $g-color10;
      }

      .mat-radio-inner-circle {
        background-color: $g-color16;

        &:before {
          content: '';
          position: absolute;
          width: rem(8);
          height: rem(4);
          top: rem(6);
          border-bottom: rem(1.5) solid $g-color10;
          @include ptl-direction(border, left, rem(1.5) solid $g-color10, true);
          @include ptl-direction(position, left, rem(5), true);
          transform: rotate(-45deg);
          z-index: 10;
        }
      }
    }
  }
}

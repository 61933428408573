/*
 * Copyright (C) 2023 - Potentially Ltd
 *
 * Please see distribution for license.
 */
@import 'colors';
@import 'rem';

body {
  &[dir='rtl'],
  [dir='rtl'] {
    .mat-slide-custom {
      &.mat-slide-toggle {
        .mat-slide-toggle-thumb-container {
          @include ptl-direction(position, right, rem(2), true);
        }

        &.mat-checked {
          .mat-slide-toggle-thumb-container {
            transform: translate3d(rem(-20), 0, 0);
          }
        }
      }
    }
  }

  &[dir='ltr'],
  [dir='ltr'] {
    .mat-slide-custom {
      &.mat-slide-toggle {
        .mat-slide-toggle-thumb-container {
          @include ptl-direction(position, left, rem(2), true);
        }

        &.mat-checked {
          .mat-slide-toggle-thumb-container {
            transform: translate3d(rem(20), 0, 0);
          }
        }
      }
    }
  }

  .mat-slide-custom {
    &.mat-slide-toggle {
      @include slide-toggle(small, $g-color10, $g-color24);
    }
  }
}
